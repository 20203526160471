import React from "react";

const SwayCoinSvg = () => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.60004 0.5H10.8C15.2183 0.5 18.8 4.08172 18.8 8.5C18.8 12.9183 15.2183 16.5 10.8 16.5H7.60004V0.5Z"
        fill="#8E7A1B"
      />
      <rect y="0.5" width="16" height="16" rx="8" fill="#BFAC4C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9952 8.21847L8.51577 16.4837C8.34526 16.4946 8.17329 16.5001 8.00003 16.5001C6.49108 16.5001 5.07969 16.0823 3.87524 15.3561L14.4108 3.71362C15.3565 4.97826 15.9368 6.53205 15.9952 8.21847Z"
        fill="#D4C765"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.39998 8.5001C2.39998 11.5929 4.90718 14.1001 7.99998 14.1001C11.0928 14.1001 13.6 11.5929 13.6 8.5001C13.6 5.4073 11.0928 2.9001 7.99998 2.9001C4.90718 2.9001 2.39998 5.4073 2.39998 8.5001ZM7.99998 2.1001C4.46535 2.1001 1.59998 4.96548 1.59998 8.5001C1.59998 12.0347 4.46535 14.9001 7.99998 14.9001C11.5346 14.9001 14.4 12.0347 14.4 8.5001C14.4 4.96548 11.5346 2.1001 7.99998 2.1001Z"
        fill="#8E7A1B"
      />
    </svg>
  );
};

export default SwayCoinSvg;
