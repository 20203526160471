import React from "react";
import { Col } from "antd";
import SwayCard from "modules/merchantV2/components/SwayCard";
import SwayText from "modules/merchantV2/components/SwayText";

interface IChartContainerProps {
  children: React.ReactNode;
  title: string;
}

const ChartContainer: React.FC<IChartContainerProps> = ({
  children,
  title,
  ...rest
}) => {
  return (
    <Col xl={12} md={24}>
      <SwayCard
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
        {...rest}
      >
        <SwayText type="title" size="small">
          {title}
        </SwayText>

        {children}
      </SwayCard>
    </Col>
  );
};

export default ChartContainer;
