import { Close } from "@material-ui/icons";
import { Box, Button, Modal, Typography } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import React from "react";

const ConfirmScreen = ({
  videoNum,
  setShowPurchaseConfirmation,
  setPurchaseConfirmed,
  setSelectedPostIds,
}) => {
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        marginBottom={2}
        component="h2"
      >
        Confirm purchase of licensing for the {videoNum} selected videos?
      </Typography>
      <Typography variant="caption" id="modal-modal-description">
        The purchase will be billed and added to your invoice
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: "#000",
            color: "#000",
            borderRadius: "16px",
            borderWidth: "2px",
            ":hover": {
              borderColor: colorsMapping.dark.primaryDark,
            },
            marginRight: "10px",
          }}
          onClick={() => {
            setShowPurchaseConfirmation(false);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: colorsMapping.light.green,
            color: "#000",
            borderRadius: "16px",
            border: "2px solid black",
            ":hover": {
              backgroundColor: colorsMapping.light.primaryDark,
            },
          }}
          disableElevation
          variant="contained"
          onClick={() => {
            setSelectedPostIds([]);

            setPurchaseConfirmed(true);
          }}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
};

const PurchaseScreen = () => {
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        marginBottom={2}
        component="h2"
        sx={{
          color: colorsMapping.dark.primaryDark,
        }}
      >
        Order Confirmed!
      </Typography>
      <Typography
        variant="caption"
        fontSize={"1rem"}
        id="modal-modal-description"
      >
        You can see the purchase in the <b>licensed content tab.</b>
      </Typography>
    </>
  );
};

const ConfirmPurchase = ({
  showPurchaseConfirmation,
  setShowPurchaseConfirmation,
  videoNum,
  purchaseConfirmed,
  setPurchaseConfirmed,
  setSelectedPostIds,
}) => {
  return (
    <Modal
      open={showPurchaseConfirmation}
      onClose={() => {
        setPurchaseConfirmed(false);
        setShowPurchaseConfirmation(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        {purchaseConfirmed ? (
          <PurchaseScreen />
        ) : (
          <ConfirmScreen
            setSelectedPostIds={setSelectedPostIds}
            videoNum={videoNum}
            setPurchaseConfirmed={setPurchaseConfirmed}
            setShowPurchaseConfirmation={setShowPurchaseConfirmation}
          />
        )}
        <Close
          style={{
            position: "absolute",
            top: 3,
            right: 5,
            cursor: "pointer",
          }}
          onClick={() => {
            setPurchaseConfirmed(false);

            setShowPurchaseConfirmation(false);
          }}
        />
      </Box>
    </Modal>
  );
};

export default ConfirmPurchase;
