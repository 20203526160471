import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import { IChartData } from "types/insights.interface";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const SwayUsaMapChart: React.FC<{ data: IChartData }> = ({ data }) => {
  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <ComposableMap
        projection="geoAlbersUsa"
        style={{ width: "100%", height: "100%", maxHeight: "500px" }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <React.Fragment key={geo.rsmKey}>
                <Geography geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />
                {data.filteredCoordinatesData.length > 0 &&
                  data.filteredCoordinatesData[0].coordinates.map(
                    (coordinate, index) => (
                      <Marker key={index} coordinates={coordinate}>
                        <circle fill="#8CE41D" stroke="#FFF" r={6} />
                      </Marker>
                    )
                  )}
              </React.Fragment>
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default SwayUsaMapChart;
