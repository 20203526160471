import { OpenInNew } from "@material-ui/icons";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import React, { useState } from "react";

const VIDEO_REQUEST_MOCK = [
  {
    id: 0,
    surveyName: "Swaypay Survey",
    feedback: 5,
    action: "View feedback",
  },
];

const SurveysTable = ({ setCurrentSurveyId }) => {
  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderRadius: "16px" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              borderBottom: "1px solid #f7f7f7",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {VIDEO_REQUEST_MOCK.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.surveyName}
                  </TableCell>
                  <TableCell>{row.feedback}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#000",
                        border: "2px solid #000",
                        borderRadius: "16px",
                        textTransform: "none",
                        ":hover": {
                          borderColor: colorsMapping.dark.primary,
                        },
                      }}
                      onClick={() => setCurrentSurveyId(row.id)}
                    >
                      {row.action}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SurveysTable;
