import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CreatorCheckbox from "./CreatorCheckbox/CreatorCheckbox";
import { colorsMapping } from "constants/spaceing";

const AGE_BRACKETS = [
  {
    label: "18-24",
  },
  {
    label: "25-34",
  },
  {
    label: "35-44",
  },
  {
    label: "45+",
  },
];

const CustomerGroup = ({
  customerGroup,
  setCustomerGroup,
  isComplete,
  setIsComplete,
}) => {
  useEffect(() => {
    setIsComplete(true);
  }, []);
  // useEffect(() => {
  //   if (
  //     customerGroup.userLevel &&
  //     customerGroup.ageBracket &&
  //     customerGroup.gender
  //   ) {
  //     if (!isComplete) {
  //       setIsComplete(true);
  //     }
  //   } else {
  //     if (isComplete) {
  //       setIsComplete(false);
  //     }
  //   }
  // }, [customerGroup.userLevel, customerGroup.ageBracket, customerGroup.gender]);

  const handleAgeBracket = (e, newAgeBracket, activeThumb) => {
    if (!Array.isArray(newAgeBracket)) {
      return;
    }

    if (activeThumb === 0) {
      setCustomerGroup({
        ...customerGroup,
        ageBracket: [
          Math.min(newAgeBracket[0], customerGroup.ageBracket[1] - 10),
          customerGroup.ageBracket[1],
        ],
      });
    } else {
      setCustomerGroup({
        ...customerGroup,
        ageBracket: [
          customerGroup.ageBracket[0],
          Math.max(newAgeBracket[1], newAgeBracket[0] + 10),
        ],
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        paddingY: 1,
      }}
    >
      <Typography marginBottom={2} variant="h6">
        Age range
      </Typography>{" "}
      <Slider
        getAriaLabel={() => "Age range"}
        value={customerGroup.ageBracket}
        onChange={handleAgeBracket}
        valueLabelDisplay="on"
        disableSwap
        sx={{
          marginBottom: 4,
          height: "10px",
          "& .MuiSlider-track, .MuiSlider-thumb": {
            color: colorsMapping.dark.primary,
          },
          "& .MuiSlider-rail": {
            color: colorsMapping.dark.lightGray,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: -6,
            backgroundColor: "unset",
            color: colorsMapping.dark.textGray,
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "#000",
            },
          },
        }}
      />
      <Typography marginBottom={2} variant="body1" fontWeight={"bold"}>
        Swaypay User Level
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: colorsMapping.dark.primary,
                },
              }}
            />
          }
          label="Beginner"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: colorsMapping.dark.primary,
                },
              }}
            />
          }
          label="Advance"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: colorsMapping.dark.primary,
                },
              }}
            />
          }
          label="Expert"
        />
      </FormGroup>
      <Typography marginBottom={2} variant="body1" fontWeight={"bold"}>
        Select Gender
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: colorsMapping.dark.primary,
                },
              }}
            />
          }
          label="Male"
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: colorsMapping.dark.primary,
                },
              }}
            />
          }
          label="Female"
        />
      </FormGroup>
    </Box>
  );
};

export default CustomerGroup;
