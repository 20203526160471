import { PersonalVideo } from "@material-ui/icons";
import { VideoCameraBackOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  Radio,
  Select,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import SwayPhoneHeartSvg from "modules/merchantV2/svg/SwayPhoneHeartSvg";
import React, { useEffect } from "react";

const CalculateForm = ({ budget, setBudget, isComplete, setIsComplete }) => {
  useEffect(() => {
    if (budget.drive && budget.type && budget.value) {
      setIsComplete(true);
    }
  }, [budget.drive, budget.type, budget.value]);
  const handleSelection = (value, field) => {
    console.log(value, field);
    if (field === "DRIVE") {
      setBudget({ ...budget, drive: value });
    }
    if (field === "TYPE") {
      setBudget({ ...budget, type: value });
    }
  };
  return (
    <Box
      sx={{
        px: 1,
        pb: 2,
        mb: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: colorsMapping.dark.secondary,
          display: "flex",
          borderRadius: "15px",
          border: "2px solid black",
          justifyContent: "space-between",
          py: 1,
          px: 2,
          mt: 2,
          "& .Mui-checked": {
            color: "white",
          },
          "& .MuiSwitch-track+.Mui-checked": {
            backgroundColor: colorsMapping.dark.primary + " !important",
          },
        }}
      >
        <Box
          display={"flex"}
          sx={{
            alignItems: "center",
          }}
        >
          <img
            width="50px"
            src="https://s3-alpha-sig.figma.com/img/1b9a/2b02/1f45e6f1bc051e7ce154c69538db9cf8?Expires=1691971200&Signature=D8QHBKmdLYz-JCvVEajcgWspzl7I-oxE-tYZKDrZTcSQuyYCoWU0Ppx7S6VVjVqkjdo4K2TmArotf0SsJK5sLcurMtMU2R7K1YIT1x13sfGpuoTOZlMBdhrMZDB5nR~AEoLa2fR7zwBqeEg3MP1kaiW66ULohw20CobgWn5F0P0~KavL~zCLSdZhowe3sjFui0Qacg8eQAgmUaRPE6zVr57yVH8ztYIXpjNiDUGtgUCVFx8HloLgJTHJcocoj-wD7KSRfALfbepauZ6tTj3ONX3P4QUVwvT1u-TkvGSQunspOn1vOckJKljNBjlE7~IIS9y5uxGnCQIoGcTSlPwr9g__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
          />
          <Typography
            sx={{
              color: "white",
              ml: 2,
            }}
            fontWeight={"bold"}
          >
            Auto Suggest Calculations
          </Typography>
        </Box>

        <Switch color="secondary" />
      </Box>

      <Typography fontWeight={"bold"} marginBottom={4}>
        Choose approx Budget
      </Typography>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Slider
          min={1000}
          max={100000}
          step={1000}
          value={budget?.value ?? 5000}
          valueLabelDisplay="on"
          valueLabelFormat={(val) => `$${val}`}
          getAriaLabel={() => "Budget"}
          onChange={(e, value) => {
            console.log(e, value);
            setBudget({ ...budget, value });
          }}
          aria-label="Budget"
          sx={{
            marginBottom: 4,
            height: "10px",
            width: "45%",
            "& .MuiSlider-track, .MuiSlider-thumb": {
              color: colorsMapping.dark.primary,
            },
            "& .MuiSlider-rail": {
              color: colorsMapping.dark.lightGray,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: -6,
              background: "white",
              borderRadius: "15px",
              boxShadow:
                "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 0px 16px -4px rgba(16, 24, 40, 0.08)",
              backgroundColor: "unset",
              color: colorsMapping.dark.textGray,
              "&:before": {
                display: "none",
              },
              "& *": {
                color: "#000",
                padding: 0.5,
              },
            },
          }}
        />
        <Select
          placeholder="Frequency..."
          sx={{
            width: "45%",
          }}
        />
      </Box>

      <Typography fontWeight={"bold"} marginBottom={1}>
        Choose what you want to drive
      </Typography>

      <Grid container columnSpacing={2} marginBottom={2}>
        <VideoRadioBox
          icon={<SwayPhoneHeartSvg active={budget.drive === "Posts"} />}
          label={"Posts"}
          subtitle={""}
          description={
            "We work with the best UGC creators who create high quality posts for Tiktok/Reels or Youtube Shorts."
          }
          field={"DRIVE"}
          isChecked={budget.drive === "Posts"}
          cols={6}
          handleChange={handleSelection}
        />
        <VideoRadioBox
          icon={<VideoCameraBackOutlined />}
          label={"Videos"}
          subtitle={""}
          description={
            "We work with the best UGC creators to deliver high quality Video content for your all socials."
          }
          field={"DRIVE"}
          isChecked={budget.drive === "Videos"}
          cols={6}
          handleChange={handleSelection}
        />
      </Grid>

      <Typography marginBottom={1} marginTop={2} fontWeight={"bold"}>
        Model it as a
      </Typography>

      <Grid container columnSpacing={2} rowSpacing={2}>
        <VideoRadioBox
          icon={<SwayPhoneHeartSvg active={budget.type === "Challenge"} />}
          label={"Challenge"}
          subtitle={""}
          description={
            "All participants can Post/ earn until the Challenge budget is exhausted."
          }
          field={"TYPE"}
          isChecked={budget.type === "Challenge"}
          cols={6}
          handleChange={handleSelection}
        />
        <VideoRadioBox
          icon={<VideoCameraBackOutlined />}
          label={"Contest"}
          subtitle={""}
          description={
            "Only the top 10 % earn based on the Post quality/Post views."
          }
          field={"TYPE"}
          isChecked={budget.type === "Contest"}
          cols={6}
          handleChange={handleSelection}
        />
        <VideoRadioBox
          icon={<VideoCameraBackOutlined />}
          label={"Content Request"}
          subtitle={""}
          description={
            "We directly engage selected Influencers to create content."
          }
          field={"TYPE"}
          isChecked={budget.type === "Content Request"}
          cols={6}
          handleChange={handleSelection}
        />
      </Grid>
    </Box>
  );
};

const VideoRadioBox = ({
  icon,
  label,
  subtitle,
  isChecked,
  description,
  handleChange,
  cols,
  field,
}) => {
  return (
    <Grid item md={cols}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: 1,
          paddingY: 2,
          borderRadius: "16px",
          border: "1px solid rgba(120,120,120,0.2)",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Avatar
          sx={{
            background: isChecked
              ? colorsMapping.light.green
              : colorsMapping.light.cardBackgroundLight,
            color: "#000",
          }}
        >
          {icon}
        </Avatar>
        <Box flexGrow={1} paddingLeft={1}>
          <Typography fontWeight={"bold"}>{label}</Typography>
          <Typography fontWeight={"bold"} variant="body2">
            {subtitle}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>

        <Box position={"absolute"} right={4} top={2}>
          <Radio
            checked={isChecked}
            onChange={() => {
              handleChange(label, field);
            }}
            sx={{
              "&.Mui-checked": {
                color: colorsMapping.dark.primary + " !important",
              },
            }}
            inputProps={{ "aria-label": "video-type" }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default CalculateForm;
