import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { IChallenge } from "types/challenge.interface";
import SwayCoinSvg from "./SwayCoinSvg";

const ChallengeCard = ({ challenge }: { challenge: IChallenge }) => {
  return (
    <Card
      sx={{
        width: "100%",
        aspectRatio: "5/6",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardMedia
        sx={{ height: "60%" }}
        image={challenge.imageUrl}
        title={challenge.challengeName}
      />
      <CardContent
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography
          gutterBottom
          variant="body1"
          fontWeight={"bold"}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          component="div"
        >
          <SwayCoinSvg /> {"$" + challenge.budgetAllocatedInCents / 1000}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {challenge.startDateAt}
        </Typography>
        <Typography
          borderTop={"1px solid rgba(120, 120, 120, 0.20)"}
          variant="h5"
          component="div"
        >
          {challenge.challengeName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ChallengeCard;
