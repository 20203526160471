import { ResponsiveFunnel } from "@nivo/funnel";

const SwayFunnelChart = ({ data }) => {
  const colorScheme = [
    "#8CE41D",
    "#6da014",
    "#ebffc7",
    "#d7ff90",
    "#66c2a4",
    "#99d8c9",
    "#ccece6",
    "#edf8fb",
  ];

  return (
    <div style={{ height: 500 }}>
      <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        valueFormat=">-.4s"
        colors={colorScheme}
        borderWidth={20}
        labelColor={{
          from: "color",
          modifiers: [["darker", 6]],
        }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
      />
    </div>
  );
};

export default SwayFunnelChart;
