import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import challengeService from "services/challenge.service";
import ChallengeCard from "./ChallengeCard";

const DraftChallenges = () => {
  const [activeChallenges, setDraftChallenges] = useState([]);
  const fetchDraftChallenges = async () => {
    try {
      const { error, challenges } =
        await challengeService.getChallengesForMerchant("DRAFT");
      console.log(challenges);
      if (challenges) {
        setDraftChallenges([...challenges]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDraftChallenges();
  }, []);

  return (
    <Grid width={"100%"} container rowSpacing={2} columnSpacing={2}>
      {activeChallenges.map((challenge) => (
        <Grid item xs={3} key={challenge.id}>
          <ChallengeCard challenge={challenge} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DraftChallenges;
