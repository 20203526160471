import { ArrowLeftOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "@material-ui/core";
import { Layout, Popover } from "antd";
import { HelpChatIcon } from "assets/icons";
import HelpTooltipIcon from "assets/images/help-tooltip.png";
import RightChevron from "assets/images/right-chevron.png";
import SwayLogo from "assets/images/sway-new-logo-black.png";
import SettingsGear from "assets/images/settings-gear.svg";
import Config from "config";
import React, { useContext, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AuthService } from "services";
import theme from "theme";
import Loading from "../../../component/loading";
import SwayButton from "../components/SwayButton";
import SwayText from "../components/SwayText";
import { SPACING, colorsMapping } from "../constants";
import useColors from "../hooks/useColors";
import MerchantBaseProvider, { MerchantContext } from "../MerchantBaseProvider";
import OnboardingFormCard from "../OnboardingForm/OnboardingFormCard";
import StatsSidebar from "../stats-sidebar/StatsSidebar";
import SwayPhoneHeartSvg from "../svg/SwayPhoneHeartSvg";
import SwayReceiptSvg from "../svg/SwayReceiptSvg";
import SwayCreditCard from "../svg/SwayCreditCard";
import useDashboardBaseStyles from "./useDashboardBaseStyles";
import DefaultProTipCard from "../OnboardingForm/ProTipCards/DefaultProTipCard";
import ProTipBaseSelector from "../OnboardingForm/ProTipCards/ProTipBaseSelector";
import { useAnalytics } from "../AnalyticsProvider";
import authService from "../../../services/auth.service";
import getPageNameFromRoute from "../../../helper/getPageNameFromRoute";
import SwayInsightsSvg from "../svg/SwayInsightsSvg";
import SwayCampaignsSvg from "../svg/SwayCampaignsSvg";
import SwayContentIcon from "../svg/SwayContentIcon";
import {
  Autocomplete,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SwaySurveryIcon from "../svg/SwaySurveryIcon";

interface ISubmenuItem {
  path?: string;
  label: string;
}

const SidebarItems = [
  {
    path: "/merchant/insights",
    icon: SwayInsightsSvg,
    label: `Insights`,
  },
  {
    path: "/merchant/campaigns",
    icon: SwayCampaignsSvg,
    label: `Campaigns`,
  },
  {
    path: "/merchant/challenges",
    icon: SwayPhoneHeartSvg,
    label: `Challenges`,
  },
  {
    path: "/merchant/surveys",
    icon: SwaySurveryIcon,
    label: `Surveys`,
  },
  {
    path: "/merchant/content",
    icon: SwayContentIcon,
    label: `Content Library`,
    submenu: [],
  },
  {
    path: "/merchant/purchases",
    icon: SwayReceiptSvg,
    label: `Purchases`,
  },
  {
    path: "/merchant/billing",
    icon: SwayCreditCard,
    label: `Billing`,
  },
];
const { Header, Content, Sider } = Layout;
const LEFT_SIDEBAR_WIDTH = 225;
const RIGHT_SIDEBAR_WIDTH = 360;
interface IProps {
  showRightSidebar: boolean;
  children?: React.ReactNode;
}
const DashboardBase: React.FC<IProps> = (props: IProps) => {
  const { showRightSidebar, children } = props;
  const classes = useDashboardBaseStyles();
  const location = useLocation();
  const history = useHistory();
  const colors = useColors();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("md"));
  const { show: openIntercomChat, shutdown: intercomShutdown } = useIntercom();
  const handleSidebarClick = () => {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    errorMessage: "",
  });

  const analytics = useAnalytics();
  const { retailer, setRetailer, proTip, retailers, setRetailers } =
    useContext(MerchantContext);
  console.log(retailers);
  const handleSideBarItemClick = (
    item: typeof SidebarItems[0],
    index: number
  ) => {
    analytics.track("Button Clicked", {
      name: item.label?.toLowerCase(),
      buttonLocation: "left menu",
      pageLocation: getPageNameFromRoute(location.pathname),
    });
    history.push(item.path);
  };

  const renderSidebarItema = (item: typeof SidebarItems[0], index: number) => {
    const isMatched = !!matchPath(location.pathname, item.path);
    return (
      <>
        <div
          onClick={() =>
            location.pathname === item.path
              ? null
              : handleSideBarItemClick(item, index)
          }
          style={{
            cursor: "pointer",
          }}
          className={isMatched ? classes.menuItemActive : classes.menuItem}
          key={index}
        >
          {React.createElement(item.icon, { active: isMatched })}
          <SwayText
            size="small"
            className={classes.menuItemLabel}
            style={{
              color: isMatched ? "#fff" : "#000",
            }}
          >
            {item.label}
          </SwayText>
        </div>
        {item.submenu && isMatched && (
          <>
            {item.submenu.map((menuItem) => {
              return (
                <p
                  onClick={() => submenuItemClicked(menuItem)}
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    cursor: "pointer",
                    padding: "5px",
                    borderRadius: "16px",
                    ...(location.pathname == menuItem.path && {
                      color: colorsMapping.light.primaryDark,
                      fontWeight: "bold",
                    }),
                  }}
                >
                  {menuItem.label}
                </p>
              );
            })}
            {item.submenu.length > 0 && <Divider />}
          </>
        )}
      </>
    );
  };

  const renderSubmenuItem = (item: ISubmenuItem) => {
    const isMatched = !!matchPath(location.pathname, item.path);
    // Need to do something better for this once localization actually happens
    const isLogout = item.label === "Logout";
    return (
      <div
        className={classes.subMenuItem}
        // We don't want to acknowledge the click at all when it matches
        onClick={() => (isMatched ? null : submenuItemClicked(item))}
        key={item.label}
        style={
          isLogout
            ? {
                borderTopWidth: 2,
                borderTopColor: colors.brandCardBorder,
                borderTopStyle: "solid",
                marginLeft: -6,
                marginRight: -6,
              }
            : null
        }
      >
        <SwayText
          size="small"
          style={isLogout ? { marginLeft: 6, marginRight: 6 } : null}
        >
          {item.label}
        </SwayText>
      </div>
    );
  };

  /**
   * For most options, this redirects to the appropriate settings page. Logout passes the intent to the auth service, then redirects the user to the login page
   */
  const submenuItemClicked = async (item: ISubmenuItem) => {
    analytics.track("Button Clicked", {
      name: item.label?.toLowerCase(),
      buttonLocation: "left menu",
      pageLocation: getPageNameFromRoute(location.pathname),
    });
    if (item.path) {
      history.push(item.path);
      return;
    }

    if (item.label !== "Logout") {
      return;
    }

    await AuthService.logout();
    intercomShutdown();
    history.push("/merchant/login");
  };

  const userSubOptions = () => {
    const options: ISubmenuItem[] = [
      { label: "Account", path: "/merchant/account" },
      { label: "Downloads", path: "/merchant/downloads" },
      { label: "Logout" },
    ];
    return (
      <div className={classes.userSubMenu}>
        {options.map((o) => renderSubmenuItem(o))}
      </div>
    );
  };
  const [isMobileRightSidebarOpen, setIsMobileRightSidbarOpen] =
    useState(false);
  const [isMobileLeftSidebarOpen, setIsMobileLeftSidbarOpen] = useState(false);
  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <Layout hasSider>
      <Sider
        width={
          isLargeDevice
            ? LEFT_SIDEBAR_WIDTH
            : isMobileRightSidebarOpen
            ? "100%"
            : 0
        }
        collapsedWidth="0"
        theme="light"
        className={classes.sidebarLeft}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img className={classes.logoStyle} src={SwayLogo} />
          {retailers.length > 1 && (
            <>
              <Autocomplete
                options={retailers.map((retailer) => ({
                  label: retailer.name,
                  value: retailer.id,
                }))}
                sx={{
                  width: "90%",
                  mb: 1,
                }}
                value={{ label: retailer.name, value: retailer.id }}
                renderInput={(params) => (
                  <TextField {...params} label="Select retailer" />
                )}
                onChange={(e, newVal) => {
                  if (newVal) {
                    const newRetailer = retailers.find(
                      (retailer) => retailer.id === newVal.value
                    );

                    const auth = JSON.parse(
                      localStorage.getItem("swaypay_auth")
                    );

                    auth.id = newVal.value;
                    auth.merchantRetailersId = [newVal.value];

                    localStorage.setItem("swaypay_auth", JSON.stringify(auth));

                    localStorage.setItem(
                      "swaypay_selected_retailer",
                      newVal.value.toString()
                    );
                    setRetailer({ ...newRetailer });
                  }
                }}
              />
            </>
          )}
          {!isLargeDevice && (
            <ArrowLeftOutlined
              onClick={() => setIsMobileRightSidbarOpen(false)}
              style={{ paddingRight: 24, fontSize: 20 }}
            />
          )}
        </div>

        <div className={classes.menuContainer}>
          {SidebarItems.map((item, index) => renderSidebarItema(item, index))}
          <div style={{ flexGrow: 1 }} />

          {/* Help and User Settings are handled differently. */}
          {/* <div
            className={classes.menuItem}
            onClick={() => {
              analytics.track("Button Clicked", {
                name: "help center",
                buttonLocation: "left menu",
                pageLocation: getPageNameFromRoute(location.pathname),
              });
              window.open(Config.INTERCOM_HELP_CENTER_URL);
            }}
          >
            <img
              src={HelpTooltipIcon}
              style={{
                width: 24,
                height: 24,
              }}
            />
            <SwayText size="small" className={classes.menuItemLabel}>
              Help Center
            </SwayText>
          </div> */}

          <Popover
            content={userSubOptions()}
            overlayInnerStyle={{ borderRadius: SPACING }}
            placement="rightBottom"
            arrowPointAtCenter
          >
            <div className={classes.menuItem}>
              <img
                src={retailer.imgUrl || SettingsGear}
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: -3,
                  marginRight: -3,
                  borderRadius: 16,
                }}
              />
              <SwayText
                size="small"
                weight="bold"
                className={classes.menuItemLabel}
              >
                {AuthService?._auth?.user?.firstName || "Settings"}
              </SwayText>
              <div style={{ flexGrow: 1 }} />
              <img
                src={RightChevron}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </div>
          </Popover>
        </div>
      </Sider>
      {showRightSidebar || !isLargeDevice ? (
        <Sider
          width={
            isLargeDevice
              ? RIGHT_SIDEBAR_WIDTH
              : isMobileLeftSidebarOpen
              ? "100%"
              : 0
          }
          theme="light"
          className={classes.sidebarRight}
        >
          <>
            {!isLargeDevice && (
              <ArrowLeftOutlined
                onClick={() => setIsMobileLeftSidbarOpen(false)}
                style={{
                  padding: `${SPACING}px 0px 0px ${SPACING}px`,
                  fontSize: 20,
                }}
              />
            )}
            {/* <OnboardingFormCard /> */}
            <div style={{ padding: 15 }}>
              {retailer &&
                (retailer.progress.info.progressPercentage === 100 ? (
                  <ProTipBaseSelector proTip={proTip} />
                ) : (
                  <OnboardingFormCard />
                ))}
              <StatsSidebar />
            </div>
          </>
        </Sider>
      ) : null}
      <Layout
        className="site-layout"
        style={{
          marginLeft: isLargeDevice ? LEFT_SIDEBAR_WIDTH : 0,
          marginRight:
            showRightSidebar && isLargeDevice ? RIGHT_SIDEBAR_WIDTH : 0,
        }}
      >
        <Content
          style={{
            margin: "0",
            overflowY: "auto",
            flexGrow: 1,
            height: "100vh",
            backgroundColor: colors.background,
          }}
        >
          {!isLargeDevice && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: `${SPACING}px ${SPACING}px 0px ${SPACING}px`,
              }}
            >
              <MenuOutlined
                style={{ fontSize: 20 }}
                onClick={() => setIsMobileRightSidbarOpen(true)}
              />
              <SwayButton
                onClick={() => setIsMobileLeftSidbarOpen(true)}
                classType="grey"
              >
                Stats
              </SwayButton>
            </div>
          )}

          {children}
        </Content>
      </Layout>
      {/* Intercom button here, over sidebar. Extra scroll for sidebar? */}
      {/* <div
        style={{
          position: "absolute",
          bottom: SPACING,
          right: SPACING,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.primaryLight,
          borderRadius: 30,
          borderStyle: "solid",
          borderColor: colors.backgroundInverted,
          borderWidth: 2,
          zIndex: 999999,
          padding: SPACING,
          cursor: "pointer",
        }}
        onClick={() => {
          analytics.track("Button Clicked", {
            name: "intercom chat",
            pageLocation: getPageNameFromRoute(location.pathname),
          });

          openIntercomChat();
        }}
      >
        <HelpChatIcon width={30} height={30} viewBox="0 0 30 30" />
      </div> */}
    </Layout>
  );
};

export default DashboardBase;
