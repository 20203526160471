import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import BackupIcon from "@mui/icons-material/Backup";
import { FileUploader } from "react-drag-drop-files";
import SwayDatePicker from "modules/merchantV2/components/SwayDatePicker";
import { DatePicker } from "antd";
import SwayButton from "modules/merchantV2/components/SwayButton";
import { colorsMapping } from "constants/spaceing";

const fileTypes = ["JPG", "PNG", "WEBP"];

const OtherForm = ({ otherInfo, setOtherInfo }) => {
  return (
    <>
      <Box
        display={"flex"}
        width={"100%%"}
        sx={{
          borderBottom: "1px solid  rgba(120, 120, 120, 0.20)",
        }}
        flexDirection={"column"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography marginBottom={2} variant="h6">
            Brand Intro
          </Typography>
          <Box>
            <Typography variant="caption">Autofill</Typography>
            <Switch color="success" />
          </Box>
        </Box>

        <TextField
          value={otherInfo.name}
          onChange={(e) => {
            setOtherInfo({
              ...otherInfo,
              intro: e.target.value,
            });
          }}
          sx={{
            marginBottom: 4,
            "& .MuiInputBase-root": {
              borderRadius: "15px",
            },
          }}
          multiline
          rows={4}
          placeholder="Describe your brand"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography marginBottom={2} variant="h6">
            Creative Direction
          </Typography>
          <Box>
            <Typography variant="caption">Autofill</Typography>
            <Switch color="success" />
          </Box>
        </Box>

        <TextField
          value={otherInfo.name}
          onChange={(e) => {
            setOtherInfo({
              ...otherInfo,
              creativeDirection: e.target.value,
            });
          }}
          sx={{
            marginBottom: 4,
            "& .MuiInputBase-root": {
              borderRadius: "15px",
            },
          }}
          multiline
          rows={4}
          placeholder="Creative direction for the video submission eg: trend, aesthetic etc."
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography marginBottom={2} variant="h6">
            Submission Rules{" "}
          </Typography>
          <Box>
            <Typography variant="caption">Autofill</Typography>
            <Switch color="success" />
          </Box>
        </Box>

        <TextField
          value={otherInfo.name}
          onChange={(e) => {
            setOtherInfo({
              ...otherInfo,
              intro: e.target.value,
            });
          }}
          sx={{
            marginBottom: 4,
            "& .MuiInputBase-root": {
              borderRadius: "15px",
            },
          }}
          multiline
          rows={4}
          placeholder="Submission guidelines for the challenge"
        />
      </Box>
    </>
  );
};

export default OtherForm;
