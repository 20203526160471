import { CheckCircle, Star, StarBorderOutlined } from "@material-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import getPageNameFromRoute from "helper/getPageNameFromRoute";
import { useAnalytics } from "modules/merchantV2/AnalyticsProvider";
import { MerchantContext } from "modules/merchantV2/MerchantBaseProvider";
import SwayCard from "modules/merchantV2/components/SwayCard";
import { SwayPostCard } from "modules/merchantV2/components/SwayPostCard";
import SwayPostCardStats from "modules/merchantV2/components/SwayPostCard/components/SwayPostCardStats";
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { IContentPost } from "types/post.interface";
import PostView from "./PostView/PostView";

const VideoLibrary = ({
  posts,
  loading,
  selectedPostIds,
  setSelectedPostIds,
}: {
  posts: IContentPost[];
  loading: boolean;
  selectedPostIds: number[];
  setSelectedPostIds: Dispatch<SetStateAction<number[]>>;
}) => {
  const analytics = useAnalytics();
  const { retailer, loading: isMerchantContextLoading } =
    useContext(MerchantContext);
  const location = useLocation();

  const [viewPost, setViewPost] = useState<{
    post: IContentPost;
    isSamplePost: boolean;
  }>({
    post: undefined,
    isSamplePost: false,
  });

  const postClicked = (post: IContentPost, isSamplePost?: boolean) => {
    if (!isSamplePost) {
      analytics.track("Button Clicked", {
        name: "buy",
        pageLocation: getPageNameFromRoute(location.pathname),
        retailer: retailer.name,
        creatorId: post?.user?.id,
        priceLevel: post?.purchaseInfo?.pricingTier?.name?.toLowerCase(),
        tiktokURL: post?.postLongUrl,
        purchaseId: post?.purchase?.id,
        contentViews: post?.latestPostStats?.viewCount,
      });
    }
    setViewPost({ post, isSamplePost });
  };

  return (
    <>
      <PostView
        post={viewPost.post}
        onCancel={() => {
          setViewPost({ ...viewPost, post: undefined });
        }}
        isSamplePost={viewPost.isSamplePost}
      />
      <Grid container columnSpacing={2}>
        {posts.map((p) => {
          return (
            <Grid
              item
              key={p.id}
              md={3}
              position={"relative"}
              onClick={(e) => {
                console.log(e);
                if (selectedPostIds.indexOf(p.id) == -1) {
                  setSelectedPostIds([...selectedPostIds, p.id]);
                } else {
                  const newSelectedPostIds = selectedPostIds.filter(
                    (postId) => postId !== p.id
                  );
                  setSelectedPostIds([...newSelectedPostIds]);
                }
              }}
              sx={{
                marginTop: "20px",
              }}
            >
              <Card
                sx={{
                  borderRadius: "16px",
                  border:
                    selectedPostIds.indexOf(p.id) !== -1
                      ? `1px solid ${colorsMapping.light.green}`
                      : "0",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <CardMedia
                  sx={{ height: "250px", position: "relative" }}
                  image={p.coverImageUrl}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 3,
                      left: 3,
                      display: "flex",
                      color: "#fff",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 28,
                        height: 28,
                        marginRight: 1.5,
                        marginLeft: 1,
                      }}
                      src={p?.userTiktokInfo?.avatarUrl}
                    />
                    <Typography variant="caption">
                      @{p?.userTiktokInfo?.displayName}
                    </Typography>
                  </Box>
                </CardMedia>
                <CardContent
                  sx={{
                    paddingY: 0,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: "nowrap",
                      overflowX: "clip",
                      textOverflow: "ellipsis",
                      height: "1.2rem",
                    }}
                    component="div"
                  >
                    {p.description}
                  </Typography>
                  <SwayPostCardStats
                    stats={p?.latestPostStats}
                    loading={loading}
                  />
                </CardContent>
                <CardActions
                  sx={{
                    paddingTop: "5px",
                    borderTop: "2px solid #f7f7f7",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    size="small"
                    sx={{
                      color: "#000",
                      textTransform: "none",
                      background: colorsMapping.light.green,
                      border: "2px solid black",
                      borderRadius: "16px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      postClicked(p, false);
                    }}
                  >
                    View
                  </Button>
                  <Button
                    size="small"
                    sx={{
                      color: "#000",
                    }}
                    disabled
                  >
                    Buy
                  </Button>
                </CardActions>
              </Card>

              {selectedPostIds.indexOf(p.id) !== -1 && (
                <Icon
                  sx={{
                    backgroundColor: "#000",
                    borderRadius: "50%",
                    position: "absolute",
                    top: 10,
                    right: 5,
                    zIndex: 3,
                  }}
                  component={CheckCircle}
                  htmlColor={colorsMapping.light.green}
                ></Icon>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default VideoLibrary;
