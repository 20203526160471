import { OpenInNew } from "@material-ui/icons";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import React, { useState } from "react";
import RequestSubmissions from "./RequestSubmissions/RequestSubmissions";

const VIDEO_REQUEST_STATUSES = ["Scheduled", "Live", "Completed"];
const VIDEO_TYPES = ["UGC", "Social Media"];

const VIDEO_REQUEST_MOCK = [
  {
    requestName: "Christmas promotion",
    type: VIDEO_TYPES[0],
    submissions: 5,
    status: VIDEO_REQUEST_STATUSES[0],
    action: "View submissions",
  },
  {
    requestName: "Product review",
    type: VIDEO_TYPES[1],

    submissions: 5,
    status: VIDEO_REQUEST_STATUSES[1],
    action: "View submissions",
  },
  {
    requestName: "Discount promotion",
    type: VIDEO_TYPES[0],

    submissions: 5,
    status: VIDEO_REQUEST_STATUSES[2],
    action: "View submissions",
  },
];

const RequestsTable = ({ setShowSubmissions }) => {
  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderRadius: "16px" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              borderBottom: "1px solid #f7f7f7",
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Submissions</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {VIDEO_REQUEST_MOCK.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.requestName}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.submissions}</TableCell>
                  <TableCell>{row.status} </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => setShowSubmissions(true)}
                      sx={{
                        color: "#000",
                        border: "2px solid #000",
                        borderRadius: "16px",
                        textTransform: "none",
                        ":hover": {
                          borderColor: colorsMapping.dark.primary,
                        },
                      }}
                    >
                      {row.action}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RequestsTable;
