import {
    IRetailerInfo,
    IRetailerListResponse,
    IRetailerListResponseJson,
    IRetailerService,
  } from "../types/retailer.interface";
  import isSuccessResponse from "helper/v2-helpers/isSuccessResponse";
  import httpClient from "./http-client";
  import httpClientV2 from "./http-clientV2";
import { IChallengeListResponse, IChallengeListResponseJson, IChallengeService } from "types/challenge.interface";
  
  class ChallengeService implements IChallengeService {
    /**
     * getRetailerInfo
     * get retailer info
     * @returns
     */

    public async uploadBannerImage(body){
      try{
            const response = await httpClientV2("/merchant-users/me/challenges/upload-banner-image",{
              apiVersion:"v2",
              method:"POST",
              auth:true,
              body,
              contentType:"multipart/form-data"
            })
            return response
      }catch(err){
        console.error(err)
      }
    }
    public async createChallenge(body){
        try{
            const response = await httpClientV2("/merchant-users/me/challenges",{
                apiVersion:"v2",
                method:"POST",
                auth:true,
                body
            })

            return response
        }catch(err){
            console.error(err)
        }
    }

    public async getChallengesForMerchant(status, limit=100,skip=0): Promise<IChallengeListResponse> {
      try {
        const response = await httpClient(`/merchant-users/me/challenges?challengeStatus=${status}&limit=${limit}&skip=0&retailerId=${localStorage.getItem("swaypay_selected_retailer")}`, {
          apiVersion: "v2",
          method: "GET",
          auth:true
        });
        if (!isSuccessResponse(response)) {
            return { error: new Error(response.message) };
        
        }

        const data: IChallengeListResponseJson = response.data;
      return { challenges: data.challenges || [] };


      } catch (err) {
        return err;
      }
    }
  
   
  }
  
  export default new ChallengeService();
  