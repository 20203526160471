import React, { useEffect } from "react";
import CreateChallengeSvg from "../svg/CreateChallengeSvg";
import { Box, Typography } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import SwayButton from "modules/merchantV2/components/SwayButton";

const NewChallenge = ({ setCreateChallenge, fetchChallenges }) => {
  useEffect(() => {
    fetchChallenges();
  }, []);
  const handleCreateChallenge = () => {
    setCreateChallenge(true);
  };
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <CreateChallengeSvg />
        <Box
          display={"flex"}
          flexDirection={"column"}
          marginY={4}
          alignItems={"center"}
        >
          <Typography
            component={"h4"}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
            }}
          >
            Looks like you are new here
          </Typography>
          <Typography
            component={"h2"}
            sx={{
              fontSize: "2.2rem",
              fontWeight: "700",
              color: colorsMapping.light.purple,
            }}
          >
            Let’s create a Challenge
          </Typography>
          <Typography
            component={"p"}
            sx={{
              fontSize: "1rem",
            }}
          >
            Getting started to your product with real people like they top
            Challengers!
          </Typography>
        </Box>

        <SwayButton
          type="primary"
          style={{
            color: colorsMapping.dark.black,
            width: "20%",
            fontSize: "1rem",
            fontWeight: "700",
          }}
          onClick={handleCreateChallenge}
        >
          Create a new challenge
        </SwayButton>
      </Box>
    </Box>
  );
};

export default NewChallenge;
