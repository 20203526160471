import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import BackupIcon from "@mui/icons-material/Backup";
import { FileUploader } from "react-drag-drop-files";
import SwayDatePicker from "modules/merchantV2/components/SwayDatePicker";
import { DatePicker } from "antd";
import SwayButton from "modules/merchantV2/components/SwayButton";
import { colorsMapping } from "constants/spaceing";

const fileTypes = ["JPG", "PNG", "WEBP"];

const BasicForm = ({ basicInformation, setBasicInformation }) => {
  return (
    <>
      <Box
        display={"flex"}
        width={"100%%"}
        sx={{
          borderBottom: "1px solid  rgba(120, 120, 120, 0.20)",
        }}
        flexDirection={"column"}
      >
        <Typography marginBottom={2} variant="h6">
          Basic information
        </Typography>{" "}
        <Typography marginBottom={2} variant="body1" fontWeight={"normal"}>
          Name
        </Typography>
        <TextField
          value={basicInformation.name}
          onChange={(e) => {
            setBasicInformation({
              ...basicInformation,
              name: e.target.value,
            });
          }}
          sx={{
            marginBottom: 4,
            "& .MuiInputBase-root": {
              borderRadius: "15px",
            },
          }}
          placeholder="Name"
        />
        <Typography marginBottom={2} variant="body1" fontWeight={"normal"}>
          Description
        </Typography>
        <TextField
          value={basicInformation.description}
          onChange={(e) => {
            setBasicInformation({
              ...basicInformation,
              description: e.target.value,
            });
          }}
          sx={{
            marginBottom: 4,
            "& .MuiInputBase-root": {
              borderRadius: "15px",
            },
          }}
          multiline
          rows={4}
          placeholder="Description"
        />
        <Typography marginBottom={2} variant="body1" fontWeight={"normal"}>
          Image
        </Typography>
        <FileUploader
          handleChange={(file) => {
            console.log(file);
            setBasicInformation({ ...basicInformation, image: file });
          }}
          name="image-upload"
          type={fileTypes}
          label="Drag & Drop/Upload"
        >
          <Box
            sx={{
              paddingX: 8,
              paddingY: 2,
              border: "1px dashed rgba(120, 120, 120, 0.20)",
              borderRadius: "15px",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={"600"}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <BackupIcon
                sx={{
                  marginRight: 1,
                }}
              />
              Drag & Drop/Upload
            </Typography>

            <Typography variant="caption">
              Supported format : JPG, PNG, WEBP
            </Typography>
          </Box>
        </FileUploader>
        <Grid container columnGap={2} marginTop={2} marginBottom={2}>
          <Grid item xs={5}>
            <Typography>Start Date:</Typography>
            <DatePicker
              style={{
                width: "100%",
              }}
              onChange={(val, date) => {
                setBasicInformation({ ...basicInformation, startDate: date });
              }}
            />
          </Grid>
          <Grid xs={5}>
            <Typography>End Date:</Typography>
            <DatePicker
              style={{
                width: "100%",
              }}
              onChange={(val, date) => {
                console.log(date);
                setBasicInformation({ ...basicInformation, endDate: date });
              }}
            />
          </Grid>
        </Grid>
        <Typography marginBottom={2} variant="body1" fontWeight={"normal"}>
          Generate Hashtags
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <TextField
            value={basicInformation.hashtags}
            onChange={(e) => {
              setBasicInformation({
                ...basicInformation,
                hashtags: e.target.value,
              });
            }}
            sx={{
              marginBottom: 4,
              marginRight: 2,
              "& .MuiInputBase-root": {
                borderRadius: "15px",
              },
              width: "80%",
            }}
            placeholder="Hashtags for the challenge, should be comma seperated."
          />
          <SwayButton>Generate</SwayButton>
        </Box>
      </Box>
    </>
  );
};

export default BasicForm;
