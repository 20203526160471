import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { IChartData } from "types/insights.interface";

const SwayAreaChart: React.FC<{ data: IChartData }> = ({ data }) => {
  const [ctrData, setData] = useState({
    labels: [] as string[],
    datasets: [
      {
        data: [0],
        fill: true,
        borderColor: "rgba(180, 241, 95, 1)",
        backgroundColor: "rgba(180, 241, 95, 0.5)",
        pointRadius: 0,
      },
    ],
  });

  const processAreaCTRData = (data: IChartData) => {
    return (
      data.offerConversionStatsForMerchant[0]?.conversion_rates?.map(
        (data) => ({
          labels: data.month,
          data: data.average_conversion_rate,
        })
      ) ?? []
    );
  };

  useEffect(() => {
    const processedData = processAreaCTRData(data);
    const labels = processedData.map((d) => d.labels);
    const areaData = processedData.map((d) => d.data);
    const chartData = {
      labels,
      datasets: [
        {
          data: areaData,
          fill: true,
          borderColor: "rgba(180, 241, 95, 1)",
          backgroundColor: "rgba(180, 241, 95, 0.5)",
          pointRadius: 0,
        },
      ],
    };

    setData(chartData);
  }, [data]);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value + "%",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return <>{data && <Line data={ctrData} options={chartOptions} />}</>;
};

export default SwayAreaChart;
