import React, { useState } from "react";
import SurveysTable from "./SurveyTable";
import SurveyShow from "./SurveyShow";

const SuveryResults = ({ setMode }) => {
  const [currentSurveyId, setCurrentSurveyId] = useState(-1);
  if (currentSurveyId !== -1) {
    return (
      <SurveyShow
        surveyId={currentSurveyId}
        setCurrentSurveyId={setCurrentSurveyId}
      />
    );
  }
  return (
    <>
      <SurveysTable setCurrentSurveyId={setCurrentSurveyId} />
    </>
  );
};

export default SuveryResults;
