import {
  IRetailerInfo,
  IRetailerListResponse,
  IRetailerListResponseJson,
  IRetailerService,
} from "../types/retailer.interface";
import isSuccessResponse from "helper/v2-helpers/isSuccessResponse";
import httpClient from "./http-client";
import httpClientV2 from "./http-clientV2";

class RetailerService implements IRetailerService {
  /**
   * getRetailerInfo
   * get retailer info
   * @returns
   */
  public async getRetailerInfo(shop: string): Promise<IRetailerInfo> {
    try {
      const response = await httpClient(`/retailers/domain/${shop}/info`, {
        apiVersion: "v2",
        method: "GET",
      });
      return response.data.retailer;
    } catch (err) {
      return err;
    }
  }

  public async getOfferConversionStats() {
    try {
      const response = await httpClientV2(`/merchant-users/me/offer-insights`, {
        apiVersion: "v2",
        method: "GET",
        auth: true,
      });

      return response.data.offerStats;
    } catch (err) {
      return err;
    }
  }

  /**
   * getRetailersForMerchant
   * Get list of retailers for a merchant
   */
  public async getRetailersForMerchant(): Promise<IRetailerListResponse> {
    try {
      const response = await httpClientV2(`/merchant-users/me/retailers`, {
        apiVersion: "v2",
        method: "GET",
        auth: true,
      });
      if (!isSuccessResponse(response)) {
        return { error: new Error(response.message) };
      }

      const data: IRetailerListResponseJson = response.data;
      return { retailers: data.retailers || [], proTip: data.proTip };
    } catch (error) {
      return { error };
    }
  }

  public async getMerchantPurchases(limit = 100, skip = 0): Promise<any[]> {
    try {
      const response = await httpClient(
        `/merchants/me/purchases?limit=${limit}&skip=${skip}`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      return [
        null,
        {
          purchases: response.data.transactions,
          totalCount: response.data.totalCount,
          stats: response.data.stats,
        },
      ];
    } catch (err) {
      return [err, null];
    }
  }

  public async getMerchantStatistics(limit = 100, skip = 0): Promise<any[]> {
    try {
      const response = await httpClient(
        `/merchants/me/statistics?limit=${limit}&skip=${skip}`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      return [
        null,
        {
          purchases: response.data.transactions,
          totalCount: response.data.totalCount,
          stats: response.data.stats,
        },
      ];
    } catch (err) {
      return [err, null];
    }
  }

  public async getMerchantTransactions(limit = 100, skip = 0): Promise<any[]> {
    try {
      const response = await httpClient(
        `/merchants/me/transactions?limit=${limit}&skip=${skip}`,
        {
          apiVersion: "v2",
          method: "GET",
          auth: true,
        }
      );
      return [
        null,
        {
          transactions: response.data.transactions,
          count: response.data.count,
        },
      ];
    } catch (err) {
      return [err, null];
    }
  }
  public async getMerchantDetails() {
    const response = await httpClientV2(`/merchant-users/me`, {
      auth: true,
      apiVersion: "v2",
      method: "GET",
    });
    if (!isSuccessResponse(response)) {
      return { error: response.message };
    }
    return { merchantUser: response.data };
  }
  public async updateMerchantDetails(data) {
    const response = await httpClientV2(`/merchant-users/me`, {
      auth: true,
      apiVersion: "v2",
      method: "PATCH",
      body: data,
    });
    if (!isSuccessResponse(response)) {
      return { error: response.message };
    }
    return { merchantUser: response.data };
  }
}

export default new RetailerService();
