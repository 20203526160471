import Config from "config";
import { PrivateRoute, RestrictedRoute } from "modules/routing.component";
import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import authService from "services/auth.service";
import AccountPage from "./account/AccountPage";
import BillingPage from "./billing-page/BillingPage";
import ContentPage from "./content-page/ContentPage";
import DownloadsPage from "./Downloads/DownloadsPage";
import ForgotPasswordPage from "./OnboardingPages/ForgotPasswordPage";
import LoginPage from "./OnboardingPages/LoginPage";
import ResetPasswordPage from "./OnboardingPages/ResetPasswordPage";
import SignupPage from "./OnboardingPages/SignupPage";
import PaymentStatusPage from "./payment-status-page/PaymentStatusPage";
import PurchasePage from "./purchase-page/PurchasePage";
import InsightsPage from "./insights-page/InsightsPage";
import CampaignsPage from "./campaigns-page/CampaignsPage";
import ChallengesPage from "./challenges-page/ChallengesPage";
import ComingSoonPage from "./comming-soon-page/ComingSoonPage";
import SurveyPage from "./survey-page/SurveyPage";

const MerchantV2: React.FC<RouteComponentProps> = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    const { _auth: authInfo } = authService;
    if (authInfo) {
      boot({
        name: authInfo.user.name,
        userHash: authInfo.user.intercomHmac,
        email: authInfo.user.email,
        hideDefaultLauncher: true,
        // Button is 60px tall and has a 16px margin beneath it. This doesn't affect mobile visitors
        verticalPadding: 92,
      });
    }
  }, [authService._auth]);
  return (
    <Switch>
      <Route exact path="/merchant">
        <Redirect to="/merchant/insights" />
      </Route>

      <RestrictedRoute
        exact
        path="/merchant/login"
        component={LoginPage}
        role={["merchant"]}
        authRedirect="/merchant/content"
      />
      <Route exact path="/merchant/signup" component={SignupPage} />
      <Route exact path="/merchant/how-it-works" component={SignupPage} />
      <Route
        exact
        path="/merchant/forgot-password"
        component={ForgotPasswordPage}
      />
      <Route
        exact
        path="/merchant/reset-password"
        component={ResetPasswordPage}
      />

      <PrivateRoute
        exact
        path="/merchant/campaigns"
        component={CampaignsPage}
        authRedirect="/merchant/login"
        role={["merchant"]}
      />
      <PrivateRoute
        exact
        path="/merchant/challenges"
        component={ChallengesPage}
        authRedirect="/merchant/login"
        role={["merchant"]}
      />
      <PrivateRoute
        exact
        path="/merchant/surveys"
        component={SurveyPage}
        authRedirect="/merchant/login"
        role={["merchant"]}
      />
      <PrivateRoute
        exact
        path="/merchant/billing"
        component={BillingPage}
        authRedirect="/merchant/login"
        role={["merchant"]}
      />
      <PrivateRoute
        path="/merchant/content"
        component={ContentPage}
        role={["merchant"]}
        authRedirect="/merchant/login"
      />
      <PrivateRoute
        exact
        path="/merchant/account"
        component={AccountPage}
        role={["merchant"]}
        authRedirect="/merchant/login"
      />
      <PrivateRoute
        exact
        path="/merchant/downloads"
        component={DownloadsPage}
        role={["merchant"]}
        authRedirect="/merchant/login"
      />
      <PrivateRoute
        exact
        path="/merchant/payments/add-payment/status"
        component={PaymentStatusPage}
        role={["merchant"]}
        authRedirect="/merchant/login"
      />
      <PrivateRoute
        exact
        path="/merchant/purchases"
        component={PurchasePage}
        label="Billing Page"
        role={["merchant"]}
        authRedirect="/merchant/login"
      />
      <PrivateRoute
        exact
        path="/merchant/insights"
        component={InsightsPage}
        authRedirect="/merchant/login"
        role={["merchant"]}
      />
    </Switch>
  );
};

export default MerchantV2;
