import { Col, Radio, RadioChangeEvent, Row } from "antd";
import { TabsPosition } from "antd/lib/tabs";
import { SPACING_0_5, SPACING_0_75, SPACING_2 } from "constants/spaceing";
import SwayTextInput from "modules/merchantV2/components/SwayTextInput";
import React, { useState } from "react";

const SurveysTab = ({ mode, handleModeChange }) => {
  return (
    <Row
      style={{
        width: "100%",
        paddingTop: SPACING_0_5,
        paddingRight: SPACING_0_75,
        paddingBottom: SPACING_0_5,
        paddingLeft: SPACING_0_75,
        flexDirection: "row",
        justifyContent: "center",
        border: "1px solid #F1F1F1",
        borderLeft: "0px",
        borderRight: "0px",
      }}
      gutter={[0, SPACING_2]}
    >
      <Col>
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{ marginBottom: 8 }}
          buttonStyle="solid"
        >
          <Radio.Button
            style={{
              color: "#000",
              fontWeight: mode == "left" ? "600" : "normal",
            }}
            value="left"
          >
            Survey Results
          </Radio.Button>
          <Radio.Button
            style={{
              color: "#000",
              fontWeight: mode == "right" ? "600" : "normal",
            }}
            value="right"
          >
            Create New
          </Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default SurveysTab;
