import {
  Box,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import SwayDatePicker from "modules/merchantV2/components/SwayDatePicker";
import React, { useEffect } from "react";
import BasicForm from "./BasicForm";
import { DatePicker } from "antd";
import OtherForm from "./OtherForm";

const OverviewForm = ({
  overviewDetails,
  setOverviewDetails,
  isComplete,
  setIsComplete,
}) => {
  useEffect(() => {
    if (
      overviewDetails.basicInformation.name &&
      overviewDetails.otherInfo.intro
    ) {
      if (!isComplete) {
        setIsComplete(true);
      }
    } else {
      if (isComplete) {
        setIsComplete(false);
      }
    }
  }, [overviewDetails.basicInformation.name, overviewDetails.otherInfo.intro]);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        spacing={2}
        width={"100%"}
        alignItems={"stretch"}
        flexDirection={"column"}
        paddingY={4}
        paddingLeft={2}
        rowGap={4}
      >
        <BasicForm
          basicInformation={overviewDetails.basicInformation}
          setBasicInformation={(basicInformationNew) => {
            setOverviewDetails({
              ...overviewDetails,
              basicInformation: basicInformationNew,
            });
          }}
        />
        <OtherForm
          otherInfo={overviewDetails.otherInfo}
          setOtherInfo={(otherInfoNew) => {
            setOverviewDetails({
              ...overviewDetails,
              otherInfo: otherInfoNew,
            });
          }}
        />
      </Grid>
    </Box>
  );
};

export default OverviewForm;
