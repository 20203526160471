import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ChallengesTab from "./ChallengesTab";
import ActiveChallenges from "./ActiveChallenges";
import DraftChallenges from "./DraftChallenges";
import SwayButton from "modules/merchantV2/components/SwayButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ShowChallenges = ({
  setCreateChallenge,
  fetchChallenges,
}: {
  setCreateChallenge: Dispatch<SetStateAction<boolean>>;
  fetchChallenges: () => {};
}) => {
  const [tab, setTab] = useState(0);
  const handleTabChange = (e, val) => {
    setTab(val);
  };
  useEffect(() => {
    fetchChallenges();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pr: 2,
          pt: 2,
        }}
      >
        <ChallengesTab value={tab} handleChange={handleTabChange} />
        <SwayButton onClick={() => setCreateChallenge(true)}>
          + Create challenge
        </SwayButton>
      </Box>

      <CustomTabPanel value={tab} index={0}>
        <ActiveChallenges />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <DraftChallenges />
      </CustomTabPanel>
    </Box>
  );
};

export default ShowChallenges;
