import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import ContentPostList from "./components/ContentPostList/ContentPostList";
import ContentPostListV2 from "./components/ContentPostList/ContentPostListV2";

const ContentPage: React.FC = () => {
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        {/* <ContentPostList /> */}
        <ContentPostListV2 />
      </DashboardBase>
    </MerchantBaseProvider>
  );
};

export default ContentPage;
