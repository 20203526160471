import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { colorsMapping } from "constants/spaceing";
import { stepConnectorClasses } from "@mui/material";

export default function ContentRequestSteps({ activeStep, steps }) {
  return (
    <Box sx={{ width: "80%", paddingY: 2 }}>
      <Stepper
        activeStep={activeStep}
        sx={{
          "& .Mui-active,  & .Mui-completed": {
            "& .MuiSvgIcon-root": {
              color: colorsMapping.light.lightPurple + " !important",
            },
          },
        }}
      >
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
