import { Box, Grid } from "@mui/material";
import SwayButton from "modules/merchantV2/components/SwayButton";
import React from "react";

const TrendSearch = ({ handleSkip }) => {
  return (
    <Grid
      item
      xs={6}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <input
        style={{
          border: "1px solid rgba(120,120,120,0.2)",
          borderRadius: "15px",
          flexGrow: 3,
          padding: "12px 16px",
        }}
        placeholder="Search Trend Name, Hashtags #"
      />

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SwayButton onClick={handleSkip}>Skip Trend</SwayButton>
      </Box>
    </Grid>
  );
};

export default TrendSearch;
