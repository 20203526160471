import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import SwayStatistic from "modules/merchantV2/components/SwayStatistic";
import {
  StatsDollar,
  StatsSales,
  StatsOfferClaims,
  StatsOfferExpired,
  StatsTikTok,
  StatsReceipt,
} from "../../icons";
import Loading from "component/loading";
import SwayButton from "modules/merchantV2/components/SwayButton";
import SawyStackedBarChart from "./SawyStackedBarChart";
import SwayFunnelChart from "./SwayfunnelChart";
import SwayAreaChart from "./SwayAreaChart";
import SwayUsaMapChart from "./SwayUsaMapChart";
import Select from "react-select";
import retailerService from "services/retailer.service";
import statsService from "services/stats.service";
import OfferBarChart from "./OfferBarChart";
import ChartContainer from "./StatsContainer";
import { IMerchantSidebarStats } from "types/stats.interface";
import {
  IChartData,
  IFunnelChartData,
  IDateSelectOptions,
} from "types/insights.interface";
import OfferPolarAreaChart from "./OfferPolarAreaChart";

const dateOptions: IDateSelectOptions[] = [
  { value: "pastMonth", label: "past month", isDisabled: true },
  { value: "allTime", label: "all time" },
];

const InsightChartContainer = () => {
  const [loading, setLoading] = React.useState(true);
  const [chartType, setChartType] = useState("allTime");
  const [chartData, setChartData] = useState<IChartData>();
  const [funnelChartData, setFunnelChartData] = useState<IFunnelChartData[]>();
  const [merchantStats, setMerhcantStats] = useState<IMerchantSidebarStats>();
  const handleDateOptions = (selectedOption: IDateSelectOptions) => {
    setChartType(selectedOption.value);
  };

  const processFunnelChartData = (data: IChartData) => {
    let totalViews = 0;
    let totalClaims = 0;
    let totalPosts = 0;
    let totalCompletions = 0;

    data.offerStatsForMerchant[0]?.stats.forEach((stat) => {
      totalViews += stat.total_views;
      totalClaims += stat.total_claims;
      totalPosts += stat.total_posts;
      totalCompletions += stat.total_completions;
    });

    return [
      {
        id: "offerViewed",
        value: totalViews,
        label: "offer viewed",
      },
      {
        id: "offerClaimed",
        value: totalClaims,
        label: "offer claimed",
      },
      {
        id: "offerPosted",
        value: totalPosts,
        label: "offer posted",
      },
      {
        id: "offerCompletion",
        value: totalCompletions,
        label: "offer completed",
      },
    ];
  };

  const getData = async () => {
    setLoading(true);
    const data = await retailerService.getOfferConversionStats();
    const sideBarstats = await statsService.getSidebarStats("?");
    setChartData(data);
    setFunnelChartData(processFunnelChartData(data));
    setMerhcantStats(sideBarstats?.stats);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [chartType]);

  return !loading ? (
    <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
      <Row
        align="middle"
        style={{
          marginBottom: 20,
        }}
      >
        <Col span={4}>
          <Select
            options={dateOptions}
            value={dateOptions.find((option) => option.value === chartType)}
            onChange={handleDateOptions}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: "10px",
              }),
            }}
          />
        </Col>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        <Col
          span={3}
          style={{
            display: "flex",
          }}
        >
          <SwayButton
            classType="grey"
            disabled={true}
            style={{
              fontSize: 14,
              width: "100%",
              paddingRight: "20px",
            }}
          >
            Export
          </SwayButton>
        </Col>
      </Row>

      {merchantStats && (
        <Row
          gutter={[8, 8]}
          style={{
            marginBottom: 20,
          }}
        >
          <Col md={8}>
            <SwayStatistic
              icon={<StatsDollar />}
              titleString="Your Cost"
              valueString={`$${merchantStats.balanceDue}`}
              direction="horizontal"
            />
          </Col>
          <Col md={8}>
            <SwayStatistic
              icon={<StatsSales />}
              titleString="Total Revenue"
              valueString={`$${merchantStats.totalRevenueInCents / 100}`}
              direction="horizontal"
            />
          </Col>
          <Col md={8}>
            <SwayStatistic
              icon={<StatsOfferClaims />}
              titleString="Total Views"
              valueString={`${merchantStats.tiktokViewCount}`}
              direction="horizontal"
            />
          </Col>
          <Col md={8}>
            <SwayStatistic
              icon={<StatsOfferExpired />}
              titleString="Total Purchases"
              valueString={`${merchantStats.purchaseCount}`}
              direction="horizontal"
            />
          </Col>
          <Col md={8}>
            <SwayStatistic
              icon={<StatsReceipt />}
              titleString="Total Posts"
              valueString={`${merchantStats.tiktokPostCount}`}
              direction="horizontal"
            />
          </Col>
          <Col md={8}>
            <SwayStatistic
              icon={<StatsTikTok />}
              titleString="Total Surveys"
              valueString={`...`}
              direction="horizontal"
            />
          </Col>
        </Row>
      )}

      {chartData && (
        <Row gutter={[16, 16]}>
          <ChartContainer title="Offer Views">
            <OfferBarChart
              barChartData={chartData}
              chartType="total_views"
              chartLabel="offers viewed"
            />
          </ChartContainer>

          <ChartContainer title=" Offer claimed">
            <OfferBarChart
              barChartData={chartData}
              chartType="total_claims"
              chartLabel="offers claimed"
            />
          </ChartContainer>

          <ChartContainer title="Offers posted">
            <OfferBarChart
              barChartData={chartData}
              chartType="total_posts"
              chartLabel="offers posted"
            />
          </ChartContainer>

          <ChartContainer title="Offers completed">
            <OfferBarChart
              barChartData={chartData}
              chartType="total_completions"
              chartLabel="offers completed"
            />
          </ChartContainer>

          <ChartContainer title="Offer conversion funnel">
            <SwayFunnelChart data={funnelChartData} />
          </ChartContainer>

          <ChartContainer title="Offer claim map">
            <SwayUsaMapChart data={chartData} />
          </ChartContainer>

          <ChartContainer title="Offer claims by states">
            <OfferPolarAreaChart data={chartData} />
          </ChartContainer>

          <ChartContainer title="Offer CTR">
            <SwayAreaChart data={chartData} />
          </ChartContainer>
          {/* <ChartContainer title="Offer Count">
          {chartData && (
            <SawyStackedBarChart
              barChartData={chartData}
              radioButtonValues={[
                "total_views",
                "total_claims",
                "total_posts",
                "total_completions",
              ]}
            />
          )}
        </ChartContainer> */}
        </Row>
      )}
    </div>
  ) : (
    <Loading type="section" />
  );
};

export default InsightChartContainer;
