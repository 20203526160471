import React from "react";
import { Button, Radio, Table } from "antd";
import httpClient from "services/http-client";
import moment from "moment";
import useStyles from "./styles";
import { AdminContext } from "modules/admin/admin-base";
import { Tag } from "antd";
import tagsService from "services/tags.service";
import formatReadbleUnderscoreString from "helper/formatReadbleUnderscoreString";
import HandlePostStatus, { POST_STATUS } from "component/Post/HandlePostStatus";
import urgentTableService from "services/urgent-table.service";
import showStandardNotficationError from "helper/v2-helpers/showStandardNotficationError";
import TagSelector from "component/TagSelector/TagSelector";
import Config from "config";
import parsePhoneNumber from "libphonenumber-js";
const { CheckableTag } = Tag;
interface IProps {}
const options = [
  { label: "Approval Pending", value: POST_STATUS.SUBMITTED },
  { label: "Cashback Pending", value: POST_STATUS.APPROVED },
];
const Posts: React.FC<IProps> = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const adminContext = React.useContext(AdminContext);

  const [defaultSelectedStatus, setDefaultSelectedStatus] = React.useState(
    POST_STATUS.SUBMITTED
  );
  const handleClickUserApplication = async (post) => {
    setData(data.filter((data) => data.id !== post.id));
  };
  const handleChangeTag = async (post, tagId, checked: boolean) => {
    try {
      setLoading(true);
      let res = null;
      const tag = adminContext.postTags.find((tag) => tag.id === tagId);
      const updatedPost = { ...post };
      if (checked) {
        res = await tagsService.postAddTag(post.id, tag.id);
        updatedPost.tags = [...post.tags, tag];
      } else {
        res = await tagsService.postDeleteTag(post.id, tag.id);
        updatedPost.tags = post.tags.filter(
          (localTag) => localTag.id != tag.id
        );
      }

      const updatedPosts = data.map((item) => {
        if (item.id === post.id) {
          return updatedPost;
        } else {
          return item;
        }
      });
      setData(updatedPosts);
      setLoading(false);
    } catch (e) {}
  };
  const columns = [
    {
      title: "Submission Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment.utc(value).format(Config.DEFAULT_DATE_FORMAT),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "id",
      render: (user) => (
        <Button
          onClick={() => window.open(`/admin/users/${user.id}`)}
          type="link"
        >
          {user.id}
        </Button>
      ),
    },
    {
      title: "TikTok",
      dataIndex: "postUrl",
      key: "postUrl",
      render: (postUrl) => (
        <Button onClick={() => window.open(postUrl)} type="link">
          TikTok Link
        </Button>
      ),
    },
    {
      title: "TikTok Handle",
      dataIndex: "user",
      key: "tiktokHandle",
      render: (user) => (
        <Button
          onClick={() =>
            window.open(`https://www.tiktok.com/@${user?.tiktokHandle}`)
          }
          type="link"
        >
          {user?.tiktokHandle}
        </Button>
      ),
    },
    {
      title: "Phone",
      dataIndex: "user",
      key: "phone",
      render: (user) => {
        const phone = parsePhoneNumber(`+${user.countryCode}${user.phoneNo}`);
        return phone?.formatInternational() || "";
      },
    },
    {
      title: "brand",
      dataIndex: "purchase",
      key: "brand",
      render: (purchase) => (
        <Tag>{purchase?.retailer?.name || "Receipt not available"}</Tag>
      ),
    },
    {
      title: "Purchase",
      dataIndex: "purchase",
      key: "purchase.id",
      render: (purchase) => (
        <Button
          onClick={() => window.open(`/admin/purchases/${purchase?.id}`)}
          type="link"
        >
          {purchase?.id}
        </Button>
      ),
    },
    {
      title: "Purchase Status",
      dataIndex: "purchase",
      key: "status",
      render: (purchase) => (
        <Tag>
          {purchase?.status
            ? formatReadbleUnderscoreString(purchase?.status)
            : "NA"}
        </Tag>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "30%",
      render: (tags, post) => {
        return (
          <TagSelector
            options={adminContext.postTags}
            selected={post.tags}
            onChange={(tagId, isSelected) =>
              handleChangeTag(post, tagId, isSelected)
            }
          />
        );
      },
    },
  ];
  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [error, posts] = await urgentTableService.getUrgentPosts(
        defaultSelectedStatus
      );
      if (error) {
        showStandardNotficationError();
      } else {
        setData(posts.filter((post) => post));
      }
      setLoading(false);
    };
    getData();
  }, [defaultSelectedStatus]);
  const classes = useStyles();

  const handlePostStatusChange = (e) => {
    setDefaultSelectedStatus(e.target.value);
  };
  return (
    <div>
      <Radio.Group
        style={{ margin: "8px 0px 12px 0px" }}
        options={options}
        onChange={handlePostStatusChange}
        value={defaultSelectedStatus}
        optionType="button"
        buttonStyle="solid"
      />
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        pagination={{ defaultPageSize: 1, pageSizeOptions: [1, 10, 20, 30] }}
        expandable={{
          expandedRowKeys: data.map((post) => post.id),
          expandedRowRender: (post) => (
            <div style={{ textAlign: "center" }}>
              <HandlePostStatus
                post={post}
                updateCallback={handleClickUserApplication}
              />
            </div>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={data}
      />
    </div>
  );
};
export default Posts;
