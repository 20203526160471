import { ArrowBack, ThumbDown, ThumbUpAlt } from "@material-ui/icons";
import { Box, Grid, Icon, Typography } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import React from "react";

const MOCK_SURVEY_FEEDBACK = [
  {
    surveyName: "Swaypay Survey",
    questions: [
      {
        label: "Product Feedback: How did you like our product?",
        options: [
          { label: "", icon: ThumbUpAlt },
          { label: "", icon: ThumbDown },
        ],
        response: ["40%", "60%"],
      },
      {
        label:
          "Brand Feedback - How likely are you to recommend this brand to your friends ?",
        options: [
          { label: "Unlikely", icon: undefined },
          { label: "Likely", icon: undefined },
          { label: "Most likely", icon: undefined },
          { label: "Definitely", icon: undefined },
        ],
        response: ["40%", "60%", "20%", "30%"],
      },
    ],
  },
  {},
];

const SurveyShow = ({ surveyId, setCurrentSurveyId }) => {
  const survey = MOCK_SURVEY_FEEDBACK[surveyId];
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: 2,
        paddingY: 3,
        position: "relative",
      }}
    >
      <ArrowBack
        style={{
          position: "absolute",
          right: 10,
          top: 5,
          fontSize: "1.5rem",
          cursor: "pointer",
        }}
        onClick={() => setCurrentSurveyId(-1)}
      />
      <Typography variant="h2">{survey.surveyName}</Typography>

      {survey.questions.map((question, index) => {
        return (
          <Box paddingY={2} paddingX={2} width={"70%"}>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
              }}
            >
              <span>Q{index + 1}.</span> <span>{question.label}</span>
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                fontSize: "1.5rem",
                marginTop: 2,
                marginLeft: 2,
              }}
            >
              {question.options.map((option, index) => {
                return (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingX: 2,
                    }}
                  >
                    {option?.icon && (
                      <Icon sx={{}}>{React.createElement(option.icon)}</Icon>
                    )}
                    {option?.label && (
                      <Typography
                        fontSize={"1.2rem"}
                        sx={{}}
                        fontWeight={"bold"}
                      >
                        {option.label}
                      </Typography>
                    )}
                    <Typography
                      fontWeight={"bold"}
                      sx={{
                        color: colorsMapping.dark.primaryDark,
                      }}
                    >
                      {question.response[index]}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default SurveyShow;
