import React, { useState } from "react";
import NewContentRequests from "./NewContentRequests/NewContentRequests";
import { Box } from "@mui/material";
import SelectTrends from "./SelectTrends/SelectTrends";
import ChallengeMenu from "./ChallengeMenu/ChallengeMenu";

const CreateChallenge = ({ setCreateChallenge }) => {
  const [trend, setTrend] = useState({ selected: false, trend: {} });

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <ChallengeMenu trend={trend} setTrend={setTrend} />
      {trend.selected ? (
        <NewContentRequests setCreateChallenge={setCreateChallenge} />
      ) : (
        <SelectTrends trend={trend} setTrend={setTrend} />
      )}
    </Box>
  );
};

export default CreateChallenge;
