import {
  CheckBox,
  PersonalVideo,
  StarBorder,
  StayCurrentPortrait,
  Videocam,
} from "@material-ui/icons";
import { MoreTime, ViewInAr } from "@mui/icons-material";
import { Avatar, Box, Checkbox, Grid, Typography } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import SwayTikTokIcon from "modules/merchantV2/svg/SwayTikTokIcon";
import React, { useEffect, useState } from "react";

const ASPECT_RATIO = {
  landscape: "Landscape",
  portrait: "Full Potrait",
};

const VideoSettings = ({
  videoSettings,
  setVideoSettings,
  setIsComplete,
  isComplete,
}) => {
  const handleSelection = (value, field) => {
    console.log(value, field);
    if (field === "TYPE") {
      setVideoSettings({ ...videoSettings, type: value });
    }
    if (field === "DURATION") {
      setVideoSettings({ ...videoSettings, duration: value });
    }
    if (field === "ASPECTRATIO") {
      setVideoSettings({ ...videoSettings, aspectRatio: value });
    }
    if (field === "PLATFORM") {
      setVideoSettings({ ...videoSettings, platform: value });
    }
  };

  useEffect(() => {
    if (
      videoSettings.type &&
      videoSettings.duration &&
      videoSettings.aspectRatio
    ) {
      if (!isComplete) {
        setIsComplete(true);
      }
    } else {
      if (isComplete) {
        setIsComplete(false);
      }
    }
  }, [videoSettings.type, videoSettings.duration, videoSettings.aspectRatio]);

  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: 8,
      }}
    >
      <Grid container spacing={2} alignItems={"stretch"}>
        <Grid
          item
          md={10}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Typography
            component={"h3"}
            paddingTop={2}
            fontWeight={"normal"}
            variant="h6"
          >
            Select video type
          </Typography>
          <Grid container spacing={2}>
            <VideoCheckBox
              icon={<Videocam />}
              label={"Video Ad"}
              subtitle={"$15"}
              cols={6}
              field={"TYPE"}
              isChecked={videoSettings.type === "Video Ad"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<SwayTikTokIcon />}
              label={"Tiktok / short / Reel"}
              subtitle={"$45"}
              cols={6}
              field={"TYPE"}
              isChecked={videoSettings.type === "Tiktok / short / Reel"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<ViewInAr />}
              label={"Product showcase"}
              subtitle={"$45"}
              field={"TYPE"}
              cols={6}
              isChecked={videoSettings.type === "Product showcase"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<StarBorder />}
              label={"Review"}
              field={"TYPE"}
              subtitle={"$45"}
              cols={6}
              isChecked={videoSettings.type === "Review"}
              handleChange={handleSelection}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems={"stretch"}>
        <Grid
          item
          md={10}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Typography
            component={"h3"}
            paddingTop={2}
            fontWeight={"normal"}
            variant="h6"
          >
            Choose Platforms
          </Typography>
          <Grid container spacing={2}>
            <VideoCheckBox
              icon={<Videocam />}
              label={"Video Ad"}
              subtitle={"$15"}
              cols={6}
              field={"PLATFORM"}
              isChecked={videoSettings.platform === "Video Ad"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<SwayTikTokIcon />}
              label={"Tiktok"}
              subtitle={"$45"}
              cols={6}
              field={"PLATFORM"}
              isChecked={videoSettings.platform === "Tiktok"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<ViewInAr />}
              label={"Instagram Reels"}
              subtitle={"$45"}
              field={"PLATFORM"}
              cols={6}
              isChecked={videoSettings.platform === "Instagram Reels"}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<StarBorder />}
              label={"Youtube Shorts"}
              field={"PLATFORM"}
              subtitle={"$45"}
              cols={6}
              isChecked={videoSettings.platform === "Youtube Shorts"}
              handleChange={handleSelection}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        minHeight={200}
        marginTop={2}
        container
        spacing={2}
        alignItems={"stretch"}
      >
        <Grid
          item
          md={10}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Typography
            component={"h3"}
            paddingTop={2}
            fontWeight={"normal"}
            variant="h6"
          >
            Select video duration
          </Typography>
          <Grid container spacing={2}>
            <VideoCheckBox
              icon={<MoreTime />}
              label={"15 Sec"}
              subtitle={"Included"}
              isChecked={videoSettings.duration === "15 Sec"}
              field={"DURATION"}
              handleChange={handleSelection}
              cols={4}
            />
            <VideoCheckBox
              icon={<MoreTime />}
              label={"30 sec"}
              subtitle={"+ $15/each video"}
              field={"DURATION"}
              isChecked={videoSettings.duration === "30 sec"}
              cols={4}
              handleChange={handleSelection}
            />
            <VideoCheckBox
              icon={<MoreTime />}
              label={"60 sec"}
              subtitle={"+ $35/each video"}
              cols={4}
              field={"DURATION"}
              isChecked={videoSettings.duration === "60 sec"}
              handleChange={handleSelection}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        minHeight={200}
        marginTop={2}
        container
        spacing={2}
        alignItems={"stretch"}
      >
        <Grid
          item
          md={10}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Typography
            component={"h3"}
            paddingTop={2}
            fontWeight={"normal"}
            variant="h6"
          >
            Select aspect ratio
          </Typography>
          <Grid container spacing={2}>
            <VideoCheckBox
              icon={<StayCurrentPortrait />}
              label={"Full Portrait"}
              subtitle={"9:16"}
              isChecked={videoSettings.aspectRatio === "Full Portrait"}
              field={"ASPECTRATIO"}
              handleChange={handleSelection}
              cols={5}
            />
            <VideoCheckBox
              icon={<PersonalVideo />}
              label={"Landscape"}
              subtitle={"16:9"}
              field={"ASPECTRATIO"}
              isChecked={videoSettings.aspectRatio === "Landscape"}
              cols={5}
              handleChange={handleSelection}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const VideoCheckBox = ({
  icon,
  label,
  subtitle,
  isChecked,
  handleChange,
  cols,
  field,
}) => {
  return (
    <Grid item md={cols}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: 1,
          paddingY: 2,
          borderRadius: "16px",
          border: "1px solid rgba(120,120,120,0.2)",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Avatar
          sx={{
            background: isChecked && colorsMapping.light.green,
            color: "#000",
          }}
        >
          {icon}
        </Avatar>
        <Box flexGrow={1} paddingLeft={1}>
          <Typography fontWeight={"bold"}>{label}</Typography>
          <Typography fontWeight={"bold"} variant="body2">
            {subtitle}
          </Typography>
        </Box>
        <Box position={"absolute"} right={4} top={2}>
          <Checkbox
            checked={isChecked}
            checkedIcon={
              <CheckBox
                style={{
                  color: colorsMapping.light.green,
                  backgroundColor: "#fff",
                }}
              />
            }
            onChange={() => {
              handleChange(label, field);
            }}
            inputProps={{ "aria-label": "video-type" }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default VideoSettings;
