import React from "react";

const SwayTikTokIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 8C3.75 5.23858 5.98858 3 8.75 3H16.75C19.5114 3 21.75 5.23858 21.75 8V16C21.75 18.7614 19.5114 21 16.75 21H8.75C5.98858 21 3.75 18.7614 3.75 16V8Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6758 11.3225C10.2055 11.3225 9.01367 12.5001 9.01367 13.9529C9.01367 15.4056 10.2055 16.5833 11.6758 16.5833C13.146 16.5833 14.3379 15.4056 14.3379 13.9529V6.5"
        stroke="black"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M17.4164 9.5419C15.7162 9.5419 14.3379 8.17999 14.3379 6.5"
        stroke="black"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwayTikTokIcon;
