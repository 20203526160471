import React from "react";

const SwaySurveryIcon = (props: { active?: boolean }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 3.1311H5.5C4.39543 3.1311 3.5 4.02653 3.5 5.1311V19.1311C3.5 20.2357 4.39543 21.1311 5.5 21.1311H19.5C20.6046 21.1311 21.5 20.2357 21.5 19.1311V5.1311C21.5 4.02653 20.6046 3.1311 19.5 3.1311Z"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 7.1311H14.5V12.1311H17.5V7.1311Z"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 7.1311H7.5V16.1311H10.5V7.1311Z"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwaySurveryIcon;
