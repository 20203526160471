import { Delete, PhonelinkRingOutlined } from "@material-ui/icons";
import {
  Box,
  Button,
  ButtonBaseTypeMap,
  ExtendButtonBase,
  Grid,
  Icon,
  Tab,
  Tabs,
  TabsTypeMap,
  Typography,
  colors,
} from "@mui/material";
import { SPACING_0_25, colorsMapping } from "constants/spaceing";
import { SwayVideo } from "modules/merchantV2/icons";
import SwayLicenseIcon from "modules/merchantV2/svg/SwayLicenseIcon";
import SwayPhoneHeartSvg from "modules/merchantV2/svg/SwayPhoneHeartSvg";
import SwayPlayIcon from "modules/merchantV2/svg/SwayPlayIcon";
import SwayReceiptSvg from "modules/merchantV2/svg/SwayReceiptSvg";
import React from "react";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabLabel = ({ currentTab, tabValue, label, icon }) => {
  const isSelected = currentTab === tabValue;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: isSelected ? colorsMapping.light.green : "#000",
      }}
    >
      {icon}
      <Typography
        marginLeft={1}
        sx={{
          textTransform: "none",
          color: "#000",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const ContentPostTabs = ({
  value,
  handleChange,
  postsSelected,
  handleLicensePurchase,
}) => {
  return (
    <Grid
      container
      spacing={2}
      padding={2}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: "16px",
            justifyContent: "space-between",
            padding: "2px",
          }}
        >
          <Tab
            label={
              <TabLabel
                icon={<SwayPhoneHeartSvg active={value === 0} />}
                label={"Video Library"}
                currentTab={value}
                tabValue={0}
              />
            }
            sx={
              value === 0
                ? {
                    borderRadius: "16px",
                    backgroundColor: "#fff",
                  }
                : {}
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <TabLabel
                icon={<SwayReceiptSvg active={value === 1} />}
                label={"Licensed content"}
                currentTab={value}
                tabValue={1}
              />
            }
            sx={
              value === 1
                ? {
                    borderRadius: "16px",
                    backgroundColor: "#fff",
                  }
                : {}
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      {postsSelected && value == 0 && (
        <Grid item display={"flex"}>
          <Button
            onClick={handleLicensePurchase}
            variant="text"
            startIcon={<SwayLicenseIcon />}
            sx={{
              color: "#000",
              textTransform: "none",
            }}
          >
            Purchase licensing
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ContentPostTabs;
