import { RadioChangeEvent, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SPACING_2 } from "../constants";
import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider, { MerchantContext } from "../MerchantBaseProvider";
import CampaignsTab from "./components/CampaignsTab/CampaignsTab";
import { TabsPosition } from "antd/lib/tabs";
import CreateCampaign from "./CreateCampaign/CreateCampaign";
import ManageCampaigns from "./ManageCampaigns/ManageCampaigns";
import ComingSoonPage from "../comming-soon-page/ComingSoonPage";

const CampaignsPage: React.FC = () => {
  const [mode, setMode] = useState<TabsPosition>("left");
  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        {/* <CampaignsTab mode={mode} handleModeChange={handleModeChange} />
        {mode == "left" && <CreateCampaign />}
        {mode == "right" && <ManageCampaigns />} */}
        <ComingSoonPage />
      </DashboardBase>
    </MerchantBaseProvider>
  );
};
export default CampaignsPage;
