import React from "react";

const SwayContentIcon = (props: { active?: boolean }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.32 2.1311H4.68C3.47602 2.1311 2.5 3.10712 2.5 4.3111V19.9511C2.5 21.1551 3.47602 22.1311 4.68 22.1311H20.32C21.524 22.1311 22.5 21.1551 22.5 19.9511V4.3111C22.5 3.10712 21.524 2.1311 20.32 2.1311Z"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 2.1311V22.1311"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 2.1311V22.1311"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 12.1311H22.5"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 7.1311H7.5"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 17.1311H7.5"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 17.1311H22.5"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 7.1311H22.5"
        stroke={props.active ? "var(--primary)" : "#000"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwayContentIcon;
