import { Grid } from "@mui/material";
import React, { useState } from "react";
import NewContentRequest from "../CreateChallenge/NewContentRequests/NewContentRequests";
import ContentRequestsTab from "./ContentRequestsTab/ContentRequestsTab";
import ManageRequests from "./ManageRequests/ManageRequests";

const ContentRequests = ({ posts, loading }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPage(newValue);
  };
  return (
    <>
      <Grid item xs={1}>
        <ContentRequestsTab
          value={currentPage}
          handleChange={handlePageChange}
        />
      </Grid>
      <Grid item paddingX={4}>
        {currentPage === 0 && (
          <NewContentRequest setCreateChallenge={handlePageChange} />
        )}
        {currentPage === 1 && (
          <ManageRequests posts={posts} loading={loading} />
        )}
      </Grid>
    </>
  );
};

export default ContentRequests;
