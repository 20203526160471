import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import VideoLibrary from "../../../../content-page/components/ContentPostTabs/VideoLibrary/VideoLibrary";
import { colorsMapping } from "constants/spaceing";
import customSnacker from "component/snackbar";
import { ArrowBackTwoTone } from "@material-ui/icons";
import ConfirmAccept from "./ConfirmAccept";

const RequestSubmissions = ({ posts, loading, setShowSubmissions }) => {
  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [showConfirmAccept, setShowConfirmAccept] = useState(false);

  const snackbar = customSnacker();
  return (
    <Box
      sx={{ widht: "100%" }}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"stretch"}
      position={"relative"}
    >
      <ConfirmAccept
        open={showConfirmAccept}
        setOpen={setShowConfirmAccept}
        videoNum={selectedPostIds.length}
      />
      <ArrowBackTwoTone
        onClick={() => setShowSubmissions(false)}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          fontSize: "2rem",
          cursor: "pointer",
        }}
      />
      <Grid
        container
        spacing={2}
        alignItems={"stretch"}
        justifyContent={"space-between"}
      >
        <Grid item md={5}>
          {/* <FormControl fullWidth>
            <InputLabel sx={{ color: "#000" }} id="demo-simple-select-label">
              Select Campaign
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={"Select Campaign"}
              sx={{
                color: "black",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: colorsMapping.dark.primaryDark,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: colorsMapping.dark.primaryDark,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: colorsMapping.dark.primaryDark,
                },
                ".MuiSvgIcon-root ": {
                  fill: colorsMapping.dark.primaryDark + " !important",
                },
              }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"halloween"}>Halloween</MenuItem>
              <MenuItem value={"summer"}>Summer sale</MenuItem>
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item md={4}>
          {selectedPostIds.length > 0 && (
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setShowConfirmAccept(true);
              }}
              sx={{
                backgroundColor: colorsMapping.light.green,
                color: "#000",
                position: "absolute",
                textTransform: "none",
                borderRadius: "16px",
                border: "2px solid black",
                ":hover": {
                  backgroundColor: colorsMapping.light.primaryDark,
                },
              }}
            >
              Accept Content
            </Button>
          )}
        </Grid>
      </Grid>
      <VideoLibrary
        posts={posts}
        loading={loading}
        selectedPostIds={selectedPostIds}
        setSelectedPostIds={setSelectedPostIds}
      />
    </Box>
  );
};

export default RequestSubmissions;
