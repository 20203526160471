import { Box, Modal, Typography } from "@mui/material";
import React from "react";

const ConfirmAccept = ({ open, setOpen, videoNum }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          marginBottom={2}
          component="h2"
        >
          {videoNum > 1
            ? videoNum + "submissions have been accepted."
            : "The submission has been accepted."}
        </Typography>
        <Typography variant="caption" id="modal-modal-description">
          A notification regarding the acceptance has been sent to the creators.
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConfirmAccept;
