import React, { useState } from "react";
import RequestsTable from "./RequestsTable";
import RequestSubmissions from "./RequestSubmissions/RequestSubmissions";

const ManageRequests = ({ posts, loading }) => {
  const [showSubmissions, setShowSubmissions] = useState(false);

  if (showSubmissions) {
    return (
      <RequestSubmissions
        setShowSubmissions={setShowSubmissions}
        posts={posts}
        loading={loading}
      />
    );
  }
  return (
    <div>
      <RequestsTable setShowSubmissions={setShowSubmissions} />
    </div>
  );
};

export default ManageRequests;
