import { RadioChangeEvent, Row } from "antd";
import React, { useEffect, useState } from "react";
import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import { TabsPosition } from "antd/lib/tabs";
import ManageRequests from "./ContentRequests/ManageRequests/ManageRequests";
import NewChallenge from "./NewChallenge/NewChallenge";
import CreateChallenge from "./CreateChallenge/CreateChallenge";
import challengeService from "services/challenge.service";
import ShowChallenges from "./ShowChallenges/ShowChallenges";

const ChallengesPage: React.FC = () => {
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [mode, setMode] = useState<TabsPosition>("left");
  const [challenges, setChallenges] = useState([]);
  const [createChallenge, setCreateChallenge] = useState(false);

  const fetchChallenges = async () => {
    try {
      const { error, challenges } =
        await challengeService.getChallengesForMerchant("DRAFT");
      console.log(challenges);
      if (challenges) {
        setChallenges(challenges);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };

  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar={showRightSidebar}>
        {challenges.length == 0 && !createChallenge && (
          <NewChallenge
            setCreateChallenge={setCreateChallenge}
            fetchChallenges={fetchChallenges}
          />
        )}
        {challenges.length > 0 && !createChallenge && (
          <ShowChallenges
            setCreateChallenge={setCreateChallenge}
            fetchChallenges={fetchChallenges}
          />
        )}
        {createChallenge && (
          <CreateChallenge setCreateChallenge={setCreateChallenge} />
        )}
      </DashboardBase>
    </MerchantBaseProvider>
  );
};
export default ChallengesPage;
