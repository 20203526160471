import { Box, Tab, Tabs } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import React from "react";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import GridViewIcon from "@mui/icons-material/GridView";

function a11yProps(index) {
  return {
    id: `challenges-tab-${index}`,
    "aria-controls": `challenges-tabpanel-${index}`,
  };
}

const ChallengesTab = ({ value, handleChange }) => {
  return (
    <Tabs
      sx={{
        borderRadius: "15px",
        width: "fit-content",
        mx: 3,
        border: "1px solid rgba(120, 120, 120, 0.2)",
        "& .MuiTab-root": {
          border: "1px solid rgba(120, 120, 120, 0.2)",
          textTransform: "none",
        },
        "& .Mui-selected": {
          color: colorsMapping.dark.black + " !important",
          border: "1px solid rgba(120, 120, 120, 0.2)",
          backgroundColor: colorsMapping.dark.primary,
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
      }}
      value={value}
      onChange={handleChange}
      aria-label="Challenges tabs"
    >
      <Tab
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ElectricBoltIcon
              sx={{
                marginRight: 1,
                fontSize: "1rem",
              }}
            />
            Active
          </Box>
        }
        {...a11yProps(0)}
      />
      <Tab
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FolderOpenIcon
              sx={{
                marginRight: 1,
                fontSize: "1rem",
              }}
            />
            Draft
          </Box>
        }
        {...a11yProps(1)}
      />
      <Tab
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MoreTimeIcon
              sx={{
                fontSize: "1rem",
                marginRight: 1,
              }}
            />
            Upcoming
          </Box>
        }
        {...a11yProps(2)}
      />
      <Tab
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <GridViewIcon
              sx={{
                marginRight: 1,
                fontSize: "1rem",
              }}
            />
            All
          </Box>
        }
        {...a11yProps(3)}
      />
    </Tabs>
  );
};

export default ChallengesTab;
