import { Box, Button, Grid, Typography, Modal, Paper } from "@mui/material";
import React, { useState } from "react";
import ContentRequestSteps from "./ContentRequestSteps/ContentRequestSteps";
import VideoSettings from "./VideoSettings";
import { colorsMapping } from "constants/spaceing";
import CustomerGroup from "./CustomerGroup/CustomerGroup";
import OverviewForm from "./Overview/OverviewForm";
import { Close } from "@material-ui/icons";
import CalculateForm from "./Calculate/CalculateForm";
import challengeService from "services/challenge.service";

const STEPS = [
  { label: "Overview" },
  { label: "User Persona" },
  { label: "Content Specs" },
  { label: "Calculate" },
];

interface StepValue {
  completed: boolean;
  label: string;
  values?: { [key: string]: string | number | boolean | object };
}

const NewContentRequests = ({ setCreateChallenge }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepValues, setStepValues] = useState<StepValue[]>(
    STEPS.map((step) => ({ label: step.label, completed: false }))
  );

  const handleSubmit = async () => {
    const payload = {
      retailerId: localStorage.getItem("swaypay_selected_retailer"),
      challengeName: stepValues[0].values["basicInformation"]["name"],
      challengeTitle: stepValues[0].values["basicInformation"]["name"],
      challengeDescription:
        stepValues[0].values["basicInformation"]["description"],
      videoDurationInSeconds: stepValues[2].values.duration,
      videoOrientation: stepValues[2].values.aspectRatio,
      challengeInstruction: stepValues[0].values.otherInfo["intro"],
      challengeStatus: "DRAFT",
      imageUrl: "",
      startDateAt: stepValues[0].values.basicInformation["startDate"],
      endDateAt: stepValues[0].values.basicInformation["endDate"],
      isActive: false,
      budgetAllocatedInCents: (stepValues[3].values.value as number) * 100,
      creativeDirectionJson:
        stepValues[0].values.otherInfo["creativeDirection"],
      coverImageUrl: "",
      tags: stepValues[0].values.basicInformation["hashtags"],
      videoType: stepValues[2].values.videoType,
    };

    console.log(payload);
    try {
      const formData = new FormData();
      console.log(stepValues[0].values.basicInformation["image"]);
      formData.append("files", stepValues[0].values.basicInformation["image"]);
      console.log(formData.get("files"));
      const imageResponse = await challengeService.uploadBannerImage(formData);

      console.log(imageResponse);
      if (imageResponse["success"]) {
        payload.imageUrl = imageResponse["data"]["url"];
      }

      const response = await challengeService.createChallenge(payload);
      console.log(response);
      if (response["data"]) {
        setCreateChallenge(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleNext = () => {
    if (stepValues[activeStep].completed && activeStep + 1 < STEPS.length) {
      setActiveStep(activeStep + 1);
    }

    if (stepValues[activeStep].completed && activeStep === STEPS.length - 1) {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (activeStep - 1 >= 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleStepValueChange = (step) => {
    return (values) => {
      const newStepValues = [...stepValues];
      newStepValues[step].values = values;
      setStepValues([...newStepValues]);
    };
  };

  const handleStepValueCompleted = (step) => {
    return (completed) => {
      const newStepValues = [...stepValues];
      newStepValues[step].completed = completed;
      setStepValues([...newStepValues]);
    };
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 2,
        padding: 4,

        margin: "auto",
      }}
    >
      <Grid container spacing={2} alignItems={"stretch"} direction={"column"}>
        <ContentRequestSteps activeStep={activeStep} steps={STEPS} />
        <Box
          sx={{
            padding: 2,
            borderRadius: "24px",
            border: "1px solid #f1f1f1",
            position: "relative",
            width: "60%",
            marginTop: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              position: "absolute",
              top: -15,
              left: 20,
              borderRadius: "15px",
              backgroundColor: colorsMapping.dark.lightPurple,
              paddingX: 2,
            }}
          >
            step {activeStep + 1}: {STEPS[activeStep].label}
          </Typography>
          {activeStep == 0 && (
            <OverviewForm
              overviewDetails={
                stepValues[0].values ?? {
                  basicInformation: {},
                  otherInfo: {},
                }
              }
              isComplete={stepValues[0].completed}
              setOverviewDetails={handleStepValueChange(0)}
              setIsComplete={handleStepValueCompleted(0)}
            />
          )}

          {activeStep == 1 && (
            <CustomerGroup
              customerGroup={
                stepValues[1].values ?? {
                  userLevel: "",
                  ageBracket: [20, 30],
                  gender: "",
                }
              }
              setCustomerGroup={handleStepValueChange(1)}
              setIsComplete={handleStepValueCompleted(1)}
              isComplete={stepValues[1].completed}
            />
          )}
          {activeStep == 2 && (
            <VideoSettings
              videoSettings={
                stepValues[2].values ?? {
                  type: "",
                  duration: "",
                  aspectRation: "",
                  platform: "",
                }
              }
              setVideoSettings={handleStepValueChange(2)}
              setIsComplete={handleStepValueCompleted(2)}
              isComplete={stepValues[2].completed}
            />
          )}

          {activeStep == 3 && (
            <CalculateForm
              budget={stepValues[3].values ?? {}}
              setBudget={handleStepValueChange(3)}
              setIsComplete={handleStepValueCompleted(3)}
              isComplete={stepValues[3].completed}
            />
          )}
        </Box>

        <Paper
          elevation={4}
          sx={{
            position: "fixed",
            width: "85vw",
            bottom: 0,
            right: 2,
            display: "flex",
            padding: 2,
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleBack}
            variant="outlined"
            color="secondary"
            sx={{
              borderColor: "#000",
              borderRadius: "16px",
              borderWidth: "2px",
              marginLeft: 2,
              color: "#000",
              ":hover": {
                borderColor: colorsMapping.dark.primaryDark,
              },
            }}
          >
            Previous
          </Button>
          <Button
            disabled={!stepValues[activeStep].completed}
            disableElevation
            sx={{
              backgroundColor: colorsMapping.light.green,
              borderRadius: "16px",
              color: "#000",
              border: stepValues[activeStep].completed && "2px solid black",

              ":hover": {
                backgroundColor: colorsMapping.light.primaryDark,
              },
            }}
            variant="contained"
            onClick={handleNext}
          >
            {activeStep >= STEPS.length - 1 ? "Submit" : "Next"}
          </Button>
        </Paper>
      </Grid>
    </Box>
  );
};

export default NewContentRequests;
