import { makeStyles } from "@material-ui/core/styles";
import { SPACING, SPACING_0_75, SPACING_4 } from "../constants";
import useColors from "../hooks/useColors";

const useDashboardBaseStyles = makeStyles((theme) => {
  const colors = useColors();
  return {
    sidebarLeft: {
      overflow: "auto",
      height: "100vh",
      position: "fixed",
      zIndex: 1000,
      left: 0,
      top: 0,
      bottom: 0,
      borderRight: "1px solid var(--CardBorder)",
    },
    logoStyle: {
      height: 31,
      marginTop: 20,
      marginBottom: 25,
      paddingLeft: 25,
      paddingRight: SPACING,
    },
    sidebarRight: {
      overflow: "auto",
      height: "100vh",
      position: "fixed",
      zIndex: 1000,
      right: 0,
      top: 0,
      bottom: 0,
      borderLeft: "1px solid var(--CardBorder)",
    },
    menuContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "calc(100vh - 76px)",
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: SPACING,
    },
    menuItem: {
      padding: "18px 12px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    menuItemActive: {
      padding: "12px 18px",
      display: "flex",
      alignItems: "center",
      borderRadius: 111,
      background: "rgba(0, 0, 0, 1)",
    },
    menuItemLabel: {
      marginLeft: 10,
      fontWeight: "bold",
    },
    userSubMenu: {
      display: "flex",
      padding: "8px 6px",
      flexDirection: "column",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colors.brandCardBorder,
      borderRadius: SPACING,
    },
    subMenuItem: {
      paddingTop: SPACING_0_75,
      paddingBottom: SPACING_0_75,
      paddingLeft: SPACING,
      paddingRight: SPACING_4,
      cursor: "pointer",
    },
  };
});

export default useDashboardBaseStyles;
