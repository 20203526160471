import { Button, notification, Typography } from "antd";
import useStyles from "./styles";
import React from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
  Link,
} from "@material-ui/core";
import CustomModal from "component/customModal";
import _ from "lodash";
import httpClient from "services/http-client";
import userV2Service from "services/user-v2.service";
import { useHistory } from "react-router-dom";
import { RECEIPT_STATUS } from "../../types/post.interface";
import SwayButton from "modules/merchantV2/components/SwayButton";

interface IProps {
  post: any;
  isMerchant?: boolean;
  updateCallback: (data: { applicationStatus: string; reason: string }) => void;
}
export enum POST_STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
  CLOSED = "CLOSED",
}
const statusToModalTitleMapping = {
  REJECTED: "Reject Post",
  CLOSED: "Reward user & close post",
};
const HandlePostStatus: React.FC<IProps> = ({
  post,
  isMerchant,
  updateCallback,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [postData, setPostData] = React.useState(null);
  const [radioValue, setRadioValue] = React.useState("");
  const closeHandler = () => {
    setPostData({ ...postData, showModal: false });
  };
  const handlePostSubmit = async (post = postData) => {
    setLoading(true);

    await httpClient(`/admin/purchases/${post.purchase.id}/posts/${post.id}`, {
      apiVersion: "v2",
      auth: true,
      method: "POST",
      body: post,
    });
    setLoading(false);
    closeHandler();
    updateCallback(postData);
  };
  const handleClickPost = (postStatus: POST_STATUS) => {
    if (postStatus === POST_STATUS.APPROVED) {
      handlePostSubmit({
        ...post,
        postStatus,
      });
    } else {
      setPostData({
        ...post,
        postStatus,
        showModal: true,
      });
    }
  };
  React.useEffect(() => {
    setPostData(_.cloneDeep(post));
  }, []);
  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPostData({ ...postData, [name]: value });
  };
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setPostData({ ...postData, rejectReason: e.target.value });
  };
  if (!postData) return null;
  const renderPostBtn = (postStatus: POST_STATUS) => {
    if (isMerchant) {
      return <Chip className="chipStatusHeight" label={postStatus} />;
    }
    switch (postStatus) {
      case POST_STATUS.SUBMITTED:
        return (
          <>
            <Button
              type="primary"
              onClick={() => handleClickPost(POST_STATUS.APPROVED)}
            >
              APPROVE
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleClickPost(POST_STATUS.REJECTED)}
            >
              REJECT
            </Button>
          </>
        );
      case POST_STATUS.APPROVED:
        return (
          <SwayButton onClick={() => handleClickPost(POST_STATUS.CLOSED)}>
            CLOSE POST
          </SwayButton>
        );

      case POST_STATUS.REJECTED:
        return (
          <>
            <Chip className="chipStatusHeight" label={postStatus} /> (
            {postData.rejectReason})
          </>
        );
      case POST_STATUS.CLOSED:
        return <Chip className="chipStatusHeight" label={postStatus} />;
    }
  };

  return (
    <>
      <CustomModal
        state={postData.showModal}
        title={statusToModalTitleMapping[postData.postStatus]}
        closeHandler={closeHandler}
        actions={
          postData.postStatus === "CLOSED" &&
          postData?.purchase?.receiptStatus ===
            RECEIPT_STATUS.MISSING ? null : (
            <SwayButton
              loading={loading}
              onClick={() => handlePostSubmit(postData)}
            >
              SUBMIT
            </SwayButton>
          )
        }
      >
        {postData.postStatus === "REJECTED" && (
          <RadioGroup
            style={{ margin: "8px 0px 12px 0px" }}
            onChange={handleRadioChange}
            value={radioValue}
          >
            <FormControlLabel
              value="Duplicate"
              control={<Radio />}
              label="Duplicate"
            />
            <FormControlLabel
              value="Inappropriate"
              control={<Radio />}
              label="Inappropriate"
            />
            <FormControlLabel
              value="Invalid TikTok"
              control={<Radio />}
              label="Invalid TikTok"
            />
          </RadioGroup>
        )}
        {postData.postStatus === "REJECTED" && (
          <TextField
            fullWidth
            label="Reject Reason"
            onChange={handleChange}
            name="rejectReason"
            value={postData.rejectReason}
            variant="outlined"
          />
        )}
        {postData.postStatus === "CLOSED" && (
          <>
            {postData.purchase.receiptStatus === RECEIPT_STATUS.VERIFIED ||
            postData.purchase.receiptStatus === RECEIPT_STATUS.NOT_REQUIRED ? (
              <>
                <TextField
                  placeholder="Cash Back Earned $0 - $100"
                  label="Cash Back Earned"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="cashbackEarned"
                  onChange={handleChange}
                  value={postData.cashbackEarned}
                />
                <FormControl
                  style={{ margin: "25px 0px 15px 2.5px" }}
                  component="fieldset"
                >
                  <FormLabel component="legend">Post Score</FormLabel>
                  <RadioGroup
                    name="score"
                    value={postData.score}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="LOW"
                      control={<Radio />}
                      label="Low"
                    />
                    <FormControlLabel
                      value="HIGH"
                      control={<Radio />}
                      label="High"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <>
                <Typography style={{ marginBottom: 10 }}>
                  Please verify receipt and add purchase price before closing
                  post
                </Typography>
                <a
                  href={`/admin/purchases/${post.purchase.id}`}
                  target="_blank"
                >
                  Verify receipt
                </a>
              </>
            )}
          </>
        )}
      </CustomModal>
      <div className={classes.btns}>{renderPostBtn(post.postStatus)}</div>
    </>
  );
};
export default HandlePostStatus;
