import React, { useEffect, useState } from "react";
import transformOfferChartData from "helper/transformOfferChartData";
import {
  Chart as ChartJS,
  ChartOptions,
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";

import { IChartData } from "types/insights.interface";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface IBarChartComponentProps {
  barChartData: IChartData;
  chartType?: string;
  radioButtonValues?: string[];
  chartLabel?: string;
}

const chartOptions: ChartOptions<"bar"> = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    y: {
      position: "left",
      stacked: true,
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const OfferBarChart: React.FC<IBarChartComponentProps> = ({
  barChartData,
  chartType,
  chartLabel,
}) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const chartData = transformOfferChartData(
      barChartData,
      chartType,
      chartLabel
    );
    setData(chartData);
  }, []);

  return (
    <>{data && <Chart type={"bar"} data={data} options={chartOptions} />}</>
  );
};

export default OfferBarChart;
