import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colorsMapping } from "constants/spaceing";
import TrendCard from "./TrendCard";
const SelectTrends = ({ trend, setTrend }) => {
  console.log(trend);
  const handleChange = (e) => {
    setTrend({ ...trend, trend: { value: e.target.value } });
  };
  return (
    <Box
      sx={{
        paddingX: 4,
        paddingTop: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel
            id="category-label"
            sx={{
              display: "flex",
            }}
          >
            <GridViewIcon />
            Choose category
          </InputLabel>

          <Select
            labelId="category-label"
            id="category"
            value={trend.trendName}
            onChange={handleChange}
            displayEmpty
            sx={{
              borderRadius: "15px",
              "& .MuiSelect-select": {
                display: "flex",
              },
            }}
            label="Choose category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>
              <FavoriteBorderIcon
                sx={{
                  marginRight: 1,
                }}
              />{" "}
              Healthcare
            </MenuItem>
            <MenuItem value={20}>
              <FavoriteBorderIcon
                sx={{
                  marginRight: 1,
                }}
              />{" "}
              Beauty
            </MenuItem>
            <MenuItem value={30}>
              <FavoriteBorderIcon
                sx={{
                  marginRight: 1,
                }}
              />{" "}
              Skincare
            </MenuItem>
          </Select>
        </FormControl>
        {trend.trend.value && (
          <>
            <ArrowForwardIcon
              sx={{
                color: colorsMapping.light.primaryDark,
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel
                id="category-label"
                sx={{
                  display: "flex",
                }}
              >
                <GridViewIcon />
                Choose subcategory
              </InputLabel>

              <Select
                labelId="category-label"
                id="category"
                value={trend.trendName}
                onChange={handleChange}
                displayEmpty
                sx={{
                  width: 200,
                  borderRadius: "15px",
                  "& .MuiSelect-select": {
                    display: "flex",
                  },
                }}
                label="Choose category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>
                  <FavoriteBorderIcon
                    sx={{
                      marginRight: 1,
                    }}
                  />{" "}
                  Ten
                </MenuItem>
                <MenuItem value={20}>
                  <FavoriteBorderIcon
                    sx={{
                      marginRight: 1,
                    }}
                  />{" "}
                  Twenty
                </MenuItem>
                <MenuItem value={30}>
                  <FavoriteBorderIcon
                    sx={{
                      marginRight: 1,
                    }}
                  />{" "}
                  Thirty
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </Box>

      {trend.trend.value && (
        <Grid marginTop={4} container columnSpacing={4} rowSpacing={2}>
          <Grid item xs={3}>
            <TrendCard trendLabel={"Hairstyle"} />
          </Grid>
          <Grid item xs={3}>
            <TrendCard trendLabel={"Hairstyle"} />
          </Grid>
          <Grid item xs={3}>
            <TrendCard trendLabel={"Hairstyle"} />
          </Grid>
          <Grid item xs={3}>
            <TrendCard trendLabel={"Hairstyle"} />
          </Grid>

          <Grid item xs={3}>
            <TrendCard trendLabel={"Hairstyle"} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SelectTrends;
