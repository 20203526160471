import { Box, Grid, Input, Typography } from "@mui/material";
import { colorsMapping } from "constants/spaceing";
import SwayButton from "modules/merchantV2/components/SwayButton";
import React from "react";
import TrendSearch from "./TrendSearch";

const ChallengeMenu = ({ trend, setTrend }) => {
  const handleSkip = () => {
    setTrend({ selected: true, trend: {} });
  };
  return (
    <Grid
      container
      width={"100%"}
      borderBottom={"1px solid rgba(120, 120, 120, 0.2)"}
    >
      <Grid
        item
        display={"flex"}
        alignItems={"baseline"}
        paddingX={4}
        paddingY={2}
        xs={6}
      >
        <Typography variant="subtitle1" color={colorsMapping.dark.gray}>
          Challenges
        </Typography>
        <Typography
          fontSize={"1rem"}
          variant="subtitle2"
          fontWeight={"700"}
          marginX={1}
        >
          {">"}
        </Typography>
        <Typography fontSize={"1rem"} variant="subtitle2" fontWeight={"700"}>
          {`${trend.selected ? "Create Challenge" : "Select Trend"}`}
        </Typography>
      </Grid>
      {!trend.selected && <TrendSearch handleSkip={handleSkip} />}
    </Grid>
  );
};

export default ChallengeMenu;
