import { RadioChangeEvent, Row } from "antd";
import React, { useState } from "react";
import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import { TabsPosition } from "antd/lib/tabs";
import { Box, Typography } from "@mui/material";
import ComingSoonSvg from "./ComingSoonSvg";
import { useLocation } from "react-router-dom";
import { colorsMapping } from "../constants";

const ComingSoonPage = ({ label = "" }) => {
  const location = useLocation();
  const pageName = location.pathname.split("/").pop();
  const labelDisplay = label
    ? label
    : pageName[0].toUpperCase() + pageName.slice(1);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        marginY: "auto",
        justifyContent: "center",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ComingSoonSvg />
        <Typography
          sx={{
            width: "70%",
            textAlign: "center",
          }}
          variant="h3"
        >
          <b
            style={{
              color: colorsMapping.light.primaryDark,
            }}
          >
            {labelDisplay}
          </b>{" "}
          is coming soon to
          <b
            style={{
              color: colorsMapping.light.primaryDark,
            }}
          >
            {" "}
            Swaypay!
          </b>
        </Typography>
      </Box>
    </Box>
  );
};
export default ComingSoonPage;
