import { XIcon } from "assets/icons";
import { SPACING, SPACING_0_75, SPACING_1_5 } from "constants/spaceing";
import SwayButton from "modules/merchantV2/components/SwayButton";
import SwayCard from "modules/merchantV2/components/SwayCard";
import useColors from "modules/merchantV2/hooks/useColors";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { TikTokEmbed } from "react-social-media-embed";
import { IContentPost } from "types/post.interface";
import BetaIcon from "assets/images/beta-icon.png";
import SwayText from "modules/merchantV2/components/SwayText";
import LicenseCheckIcon from "assets/images/license-check.png";
import SwayLink from "modules/merchantV2/components/SwayLink";
import OpenProfileIcon from "assets/images/open-profile.png";
import postService from "services/post.service";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Avatar, Box, Checkbox, Icon, Modal } from "@mui/material";
import { Star, StarBorder } from "@material-ui/icons";
import { yellow } from "@mui/material/colors";

const PostView = ({
  post,
  isSamplePost,
  onCancel,
}: {
  post: IContentPost;
  onCancel: () => void;
  isSamplePost: boolean;
}) => {
  const colors = useColors();
  const rowSizeStyles = { display: "flex", width: "100%" };
  const [termsApproved, setTermsApproved] = useState(false);
  const service = postService;
  const [highlightedIndex, setHighlightedIndex] = useState(2);
  const termsCheckboxChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setTermsApproved(e.target.checked);
  };

  const purchaseButtonClicked = async () => {
    const { downloadUrl, error } = await service.purchasePost(post.id);
    if (error) {
      // Show error, card info? several error types could happen here
      return;
    }

    // Download immediately or redirect to downloads page?
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    }
  };

  return (
    <Modal
      open={post !== undefined}
      onClose={() => {
        onCancel();
      }}
      sx={{
        width: 900,
        height: "80%",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "50%",
            backgroundColor: colors.backgroundInverted,
            borderTopLeftRadius: SPACING,
            borderBottomLeftRadius: SPACING,
            flexDirection: "column",
            flexGrow: 1,
            overflow: "scroll",
          }}
        >
          <div style={{ flexGrow: 1 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: SPACING_1_5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Fill center column with post video, how to ensure aspect ratio? 
          235/416*/}
            <div
              style={{
                position: "relative",
                //height: 800,
                backgroundColor: colors.primary,
                borderRadius: SPACING,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TikTokEmbed url={post?.postLongUrl} width={325} />
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            padding: `${SPACING_0_75}px ${SPACING}px`,
            overflow: "auto",
            background: colors.background,
          }}
        >
          <div
            style={{
              flexDirection: "row-reverse",
              ...rowSizeStyles,
            }}
          >
            <SwayButton
              classType={null}
              ghost
              onClick={() => {
                onCancel();
              }}
              style={{
                borderWidth: 0,
                padding: 0,
              }}
            >
              <XIcon />
            </SwayButton>
          </div>

          <SwayCard
            style={{
              width: "100%",
              marginTop: SPACING,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                paddingBottom: SPACING,
              }}
            >
              <Avatar
                src={post?.userTiktokInfo?.avatarUrl}
                sx={{
                  width: 32,
                  height: 32,
                  borderWidth: 2,
                  borderColor: colors.backgroundInverted,
                  backgroundColor: colors.background,
                  borderStyle: "solid",
                  marginRight: SPACING,
                }}
              />
              <div style={{ flexDirection: "column", display: "flex" }}>
                <SwayText size="small" weight="bold">
                  {post?.userTiktokInfo?.tiktokHandle}
                </SwayText>
                <SwayText size="small">
                  {post?.userTiktokInfo?.displayName}
                </SwayText>
              </div>
              <div style={{ flexGrow: 1 }} />
              <img
                src={OpenProfileIcon}
                onClick={() =>
                  window.open(post?.userTiktokInfo?.profileDeepLink, "_blank")
                }
                style={{
                  minWidth: 18,
                  width: 18,
                  height: 18,
                  marginRight: SPACING_0_75,
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: SPACING,
                marginBottom: SPACING,
              }}
            >
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginRight: 6,
                }}
              >
                {post?.userTiktokInfo?.followingCountString}
              </SwayText>
              <SwayText size="small">Following</SwayText>
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginLeft: SPACING_0_75,
                  marginRight: 6,
                }}
              >
                {post?.userTiktokInfo?.followerCountString}
              </SwayText>
              <SwayText size="small">Followers</SwayText>
              <SwayText
                size="small"
                weight="bold"
                style={{
                  marginLeft: SPACING_0_75,
                  marginRight: 6,
                }}
              >
                {post?.userTiktokInfo?.likeCountString}
              </SwayText>
              <SwayText size="small">Likes</SwayText>
            </div>
            <SwayText size="small">{post?.userTiktokInfo?.tiktokBio}</SwayText>
          </SwayCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingY: 2,
            }}
          >
            <SwayText>Rate content</SwayText>
            <ReviewWidget
              stars={5}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
            />
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SwayText>Feedback</SwayText>
            <textarea
              style={{
                borderRadius: "5px",
                padding: "5px",
              }}
              placeholder="Add feedback for the content..."
            ></textarea>
            <SwayButton
              style={{
                width: "20%",
                marginTop: 8,
              }}
            >
              Submit
            </SwayButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ReviewWidget = ({ stars, highlightedIndex, setHighlightedIndex }) => {
  const [highlight, setHighlight] = useState(highlightedIndex);
  return (
    <Box>
      {[...Array(stars)].map((_, index) => {
        return (
          <Icon
            sx={{
              ".MuiSvgIcon-root": {
                fill: index <= highlight ? yellow[500] : "transparent",

                stroke: "#000",
                strokeWidth: "2px",
                cursor: "pointer",
              },
            }}
            onMouseOver={() => {
              setHighlight(index);
            }}
            onMouseOut={() => {
              setHighlight(highlightedIndex);
            }}
            onClick={() => setHighlightedIndex(index)}
          >
            <Star />
          </Icon>
        );
      })}
    </Box>
  );
};

export default PostView;
