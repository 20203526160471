import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { IChartData } from "types/insights.interface";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip);

const generateRandomGreenShades = (count: number) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const green = Math.floor(Math.random() * 256);
    colors.push(`rgba(0, ${green}, 0, 0.6)`);
  }
  return colors;
};

const OfferPolarAreaChart: React.FC<{ data: IChartData }> = ({ data }) => {
  const polarChartData = data.filteredUserOfferClaimStates;
  const [polarData, setPolarData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const chartOptions = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const states = polarChartData[0]?.count.map((item) => item.state) || [];
    const counts = polarChartData[0]?.count.map((item) => item.claimCount);
    const backgroundColors =
      states.length > 0
        ? generateRandomGreenShades(states.length)
        : ["#8CE41D"];
    const chartData = {
      labels: states,
      datasets: [
        {
          data: counts,
          backgroundColor: backgroundColors,
        },
      ],
    };
    setPolarData(chartData);
  }, [polarChartData]);

  return (
    <div
      style={{
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          maxHeight: "80%",
          flexWrap: "wrap",
          width: "50%",
        }}
      >
        {polarData.labels.length > 0 &&
          polarData?.labels?.map((label, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: polarData.datasets[0].backgroundColor[index],
                }}
              />
              <span style={{ marginLeft: "5px" }}>{label}</span>
            </div>
          ))}
      </div>
      {polarData && <PolarArea data={polarData} options={chartOptions} />}
    </div>
  );
};

export default OfferPolarAreaChart;
