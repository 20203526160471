import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { colorsMapping } from "constants/spaceing";
import { Line } from "react-chartjs-2";
import SearchIcon from "@mui/icons-material/Search";
import StorefrontIcon from "@mui/icons-material/Storefront";

const data = [30, 45, 50, 40, 55, 70, 65, 60, 50, 45, 40, 35];
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const chartData = {
  labels: labels,
  datasets: [
    {
      data: data,
      fill: true,
      borderColor: "#B4F15F",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const chartOptions = {
  plugins: {
    filler: {
      propagate: true,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => value + "%",
      },
    },
  },
};

const TrendCard = ({ trendLabel }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        borderRadius: "15px",
        paddingY: 0.75,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingX: 1,
          marginBottom: 2,
        }}
      >
        <Box>
          <Typography variant="h6">{trendLabel}</Typography>
          <Typography variant="caption">+74% YoY</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: colorsMapping.dark.primaryDark,
          }}
        >
          <TrendingUpIcon />
          On the rise
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: 1,
        }}
      >
        <Line data={chartData} options={chartOptions} />
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: 1,
          justifyContent: "space-between",
          marginTop: 2,
          borderTop: "1px solid rgba(120, 120, 120, 0.20)",
        }}
      >
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SearchIcon
            sx={{
              fontSize: "1rem",
            }}
          />
          2.8M
        </Typography>

        <Typography
          variant="body2"
          fontWeight={500}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StorefrontIcon
            sx={{
              fontSize: "1rem",
              marginRight: 0.2,
            }}
          />
          6.5K
        </Typography>

        <Typography variant="body2" fontWeight={500}>
          21
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          +5.9%
        </Typography>
      </Box>
    </Paper>
  );
};

export default TrendCard;
