import { Box, Button, Grid, Modal, Tabs, Typography } from "@mui/material";
import { SPACING, colorsMapping } from "constants/spaceing";
import React, { useEffect, useState } from "react";
import ContentPostTabs from "../ContentPostTabs/ContentPostTabs";
import { IContentPost, IPostService } from "types/post.interface";
import postService from "services/post.service";
import VideoLibrary from "../ContentPostTabs/VideoLibrary/VideoLibrary";
import LicensedContent from "../ContentPostTabs/LicensedContent/LicensedContent";
import { PrivateRoute } from "modules/routing.component";
import ContentRequests from "../../../challenges-page/ContentRequests/ContentRequests";
import { Route, Switch } from "react-router-dom";
import { Close } from "@material-ui/icons";
import ConfirmPurchase from "./ConfirmPurchase";

const ContentPostListV2 = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const service: IPostService = postService;

  const [posts, setPosts] = useState<IContentPost[]>([]);
  const [showPurchaseConfirmation, setShowPurchaseConfirmation] =
    useState(false);

  const [purchaseConfirmed, setPurchaseConfirmed] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPostIds, setSelectedPostIds] = useState<number[]>([]);

  const loadPosts = async () => {
    setLoading(true);
    try {
      const { posts: newPosts } = await postService.getPosts("");
      console.log(newPosts);
      setPosts([...newPosts]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  const handlePageChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPage(newValue);
  };

  const handleLicensePurchase = () => {
    setShowPurchaseConfirmation(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Switch>
        <Route exact path={"/merchant/content/request"}>
          <ContentRequests posts={posts} loading={loading} />
        </Route>
        <Route exact path={"/merchant/content"}>
          <Grid item xs={1}>
            <ContentPostTabs
              value={currentPage}
              handleLicensePurchase={handleLicensePurchase}
              postsSelected={selectedPostIds.length !== 0}
              handleChange={handlePageChange}
            />
          </Grid>
          <Grid item paddingX={4}>
            {currentPage == 0 && (
              <VideoLibrary
                posts={posts}
                selectedPostIds={selectedPostIds}
                setSelectedPostIds={setSelectedPostIds}
                loading={loading}
              />
            )}
            {currentPage == 1 && <LicensedContent />}
          </Grid>
        </Route>
      </Switch>
      <ConfirmPurchase
        showPurchaseConfirmation={showPurchaseConfirmation}
        setShowPurchaseConfirmation={setShowPurchaseConfirmation}
        videoNum={selectedPostIds.length}
        setSelectedPostIds={setSelectedPostIds}
        purchaseConfirmed={purchaseConfirmed}
        setPurchaseConfirmed={setPurchaseConfirmed}
      />
    </Box>
  );
};

export default ContentPostListV2;
