import { OpenInNew } from "@material-ui/icons";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const LICENSE_REQUEST_MOCK = [
  {
    video:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    views: 123,
    preview:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    action: "Download",
  },
  {
    video:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    views: 232,
    preview:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    action: "Download",
  },
  {
    video:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    views: 324,
    preview:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    action: "Download",
  },
  {
    video:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    views: 324234,
    preview:
      "https://images.pexels.com/photos/15360894/pexels-photo-15360894/free-photo-of-woman-standing-on-the-beach.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load",
    action: "Download",
  },
];

const LicensedContent = () => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderRadius: "16px" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              borderBottom: "1px solid #f7f7f7",
            }}
          >
            <TableRow>
              <TableCell>Video</TableCell>
              <TableCell>Views</TableCell>
              <TableCell>Preview</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {LICENSE_REQUEST_MOCK.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <img
                      width={"100px"}
                      height={"100px"}
                      src={row.video}
                      style={{
                        borderRadius: "16px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{row.views}</TableCell>
                  <TableCell>
                    <a
                      href={row.preview}
                      style={{
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                      }}
                      target="_blank"
                    >
                      {" "}
                      View Post
                      <OpenInNew
                        style={{
                          marginLeft: "5px",
                          fontSize: "0.9rem",
                        }}
                      />
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#000",
                        border: "2px solid #000",
                        borderRadius: "16px",
                        textTransform: "none",
                      }}
                    >
                      {row.action}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LicensedContent;
