import React from "react";

const SwayLicenseIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4 13.4979V16.4979C20.3997 16.8487 20.3071 17.1931 20.1316 17.4968C19.956 17.8004 19.7038 18.0526 19.4 18.2279L12.4 22.2279C12.096 22.4035 11.7511 22.4959 11.4 22.4959C11.049 22.4959 10.7041 22.4035 10.4 22.2279L3.40002 18.2279C3.09629 18.0526 2.844 17.8004 2.66849 17.4968C2.49297 17.1931 2.40038 16.8487 2.40002 16.4979V8.49795C2.40038 8.14722 2.49297 7.80276 2.66849 7.49911C2.844 7.19546 3.09629 6.94331 3.40002 6.76795L10.4 2.76795C10.7041 2.59241 11.049 2.5 11.4 2.5C11.7511 2.5 12.096 2.59241 12.4 2.76795L14.15 3.76795L15.025 4.26795"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.67004 7.45801L11.4 12.508L14.15 10.9172L15.525 10.1218L16.2125 9.72414"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4 22.578V12.498"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.2291 9.64453H20.2354"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4 4.61589C18.5478 4.19581 18.8395 3.84157 19.2234 3.61594C19.6073 3.3903 20.0587 3.30782 20.4976 3.3831C20.9366 3.45839 21.3347 3.68658 21.6215 4.02726C21.9082 4.36795 22.0652 4.79913 22.0645 5.24445C22.0645 6.50158 20.1789 7.13014 20.1789 7.13014"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwayLicenseIcon;
