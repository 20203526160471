import { IChartData, IChartDataSet } from "types/insights.interface";

const transformOfferChartData = (
  data: IChartData,
  property: string,
  chartLabel?: string
) => {
  const offerConversionStats = data.offerStatsForMerchant;

  const labelsSet = new Set(
    offerConversionStats.flatMap((retailer: any) =>
      retailer.stats.map((stat: any) => stat.month)
    )
  );

  const labels = Array.from(labelsSet)
    .sort()
    .map((month) => {
      return month;
    });

  const counts: number[] = [];

  labelsSet.forEach((month) => {
    const values = offerConversionStats
      .flatMap((retailer: any) =>
        retailer.stats
          .filter((stat: any) => stat.month === month)
          .map((stat: any) => stat[property])
      )
      .sort((a: number, b: number) => a - b);

    const total = values.reduce((acc: any, val: any) => acc + val, 0);
    counts.push(total);
  });

  const datasets: IChartDataSet[] = [
    {
      label: chartLabel ? chartLabel : "count",
      backgroundColor: "rgba(180, 241, 95, 0.5)",
      borderColor: "rgba(180, 241, 95, 0.5)",
      stack: "now",
      data: counts,
      type: "bar",
    },
  ];

  return {
    labels: labels,
    datasets: datasets,
  };
};

export default transformOfferChartData;
