import { RadioChangeEvent, Row } from "antd";
import React, { useState } from "react";
import DashboardBase from "../DashboardBase/DashboardBase";
import MerchantBaseProvider from "../MerchantBaseProvider";
import { TabsPosition } from "antd/lib/tabs";
import SurveysTab from "./SurveysTab/SurveysTab";
import SuveryResults from "./SurveyResults/SurveyResults";
import ComingSoonPage from "../comming-soon-page/ComingSoonPage";

const SurveyPage: React.FC = () => {
  const [mode, setMode] = useState<TabsPosition>("left");
  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };
  return (
    <MerchantBaseProvider>
      <DashboardBase showRightSidebar>
        <SurveysTab mode={mode} handleModeChange={handleModeChange} />
        {mode == "left" && <SuveryResults setMode={setMode} />}
        {mode == "right" && <ComingSoonPage />}
      </DashboardBase>
    </MerchantBaseProvider>
  );
};
export default SurveyPage;
